import { createI18n } from "vue-i18n";
import { translation } from "@/api/base";
import { useAuthStore } from "./stores/AuthStore";

// Function to fetch translations from the API
async function fetchTranslations(locale) {
  try {
    const {translations} = await translation.readTranslations(locale);
    return translations;
  } 
  catch (error) {
    console.error('Error fetching translations:', error)
    return {}
  }
}


export async function loadTranslations(locale) {
  
  const {lang, setLang} = useAuthStore();

  const l = locale || lang;

  setLang(l);

  const translations = await fetchTranslations(l);

  try{
    i18n.global.mergeLocaleMessage(i18n.global.locale.value, translations);
  }
  catch(e){
    console.log(e);
  }
}


export const i18n = createI18n({
  locale: 'en',
  legacy: false,
  messages:{},
  fallbackWarn: false,
  missingWarn: false,
  warnHtmlMessage: false
})