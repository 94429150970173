<template>
  <BaseBox v-if="monitorData" class="space-y-4">
    <h2 class="text-2xl font-bold">{{ $t('title_quality') }}</h2>
    <div class="flex gap-4 flex-wrap">
      <div class="space-y2 grow">
        <div>
          <small>{{ $t('text_last_24_global_activity') }}</small>
          <StatusBars :data="monitorData.statusDetails.uptimeDetails.daily" />
        </div>
        <div>
          <small>{{ $t('text_last_30_days_activity') }}</small>
          <StatusBars :data="monitorData.statusDetails.uptimeDetails.monthly" />
        </div>
        <div>
          <small>{{ $t('text_last_1_year_activity') }}</small>
          <StatusBars :data="monitorData.statusDetails.uptimeDetails.annual" />
        </div>
      </div>
      <div class="space-y-2">
        <div v-if="monitorData.statusDetails.lastEvent">
          <small>{{ $t('title_last_down') }}</small>
          <div class="text-lg font-medium">
            {{
              timePassed(
                monitorData.statusDetails.lastEvent,
                "minutes"
              )
            }}
          </div>
        </div>
        <div v-if="monitorData.statusDetails.uptime.monthly">
          <small>30D Uptime</small>
          <div class="text-lg font-medium">
            {{ monitorData.statusDetails.uptime.monthly }}%
          </div>
        </div>
        <div v-if="monitorData.statusDetails.uptime.annual">
          <small>1Y Uptime</small>
          <div class="text-lg font-medium">
            {{ monitorData.statusDetails.uptime.annual }}%
          </div>
        </div>
      </div>
    </div>
  </BaseBox>
</template>

<script setup>
import { monitor } from "@/api/base";
import { ref } from "vue";
import { timePassed } from "@/utilities/helpers";

const props = defineProps({
  monitorId: {
    type: [Number, String],
    required: true,
  },
});

const monitorData = ref(null);

monitor.read(props.monitorId).then(({ monitor }) => {
  monitorData.value = monitor;
});
</script>
