import { useAuthStore } from "@/stores/AuthStore";
import { useUserStore } from "@/stores/UserStore";
import useToastStore from "@/stores/toast";
import { i18n } from "@/i18n";
const { t } = i18n.global;

export function permissions({to, from, next}){
  const authStore = useAuthStore();
  const userStore = useUserStore();
  const { addToast } = useToastStore();
  if(to.meta?.permission && !authStore.checkPermission(to.meta.permission, userStore.user.permissions)) {
    addToast({
      title: t('text_permission_denied'),
      content: t('text_permission_denied_reason'),
      variant: "danger",
    })
    if(from.name) return next({ name: from.name });
    else return next({ name: "UnauthorizedPage" });
  }
  else return next();
}

export default function ({ next }) {
  const authStore = useAuthStore();
  if (!authStore.isLoggedIn) {
    return next({ name: "LoginPage" });
  }
  return next();
}
