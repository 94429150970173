<template>
  <div class="base-textarea" :class="{ error: errorMessage }">
    <label v-if="label" :for="$attrs.id"
      >{{ label }}
      <span class="text-red-600 dark:text-red-400 font-medium" v-if="required">*</span></label
    >
    <textarea
      v-bind="$attrs"
      v-model="value"
      :name="$attrs.name"
      :rows="rows"
      v-on="validationListeners"
    >
    </textarea>
    <span v-if="errorMessage" class="error-message">{{ errorMessage }}</span>
  </div>
</template>

<script>
// use normal <script> to declare options
export default {
  inheritAttrs: false,
};
</script>

<script setup>
import { useField } from "vee-validate";

const props = defineProps({
  label: String,
  name: String,
  rows: {
    type: Number,
    default: 2,
  },
  required: Boolean,
  initialValue: String,
});

// const { value } = useField(() => props.name, undefined);

const { value, errorMessage, handleChange, handleBlur } = useField(
  () => props.name,
  undefined,
  {
    validateOnValueUpdate: false,
    initialValue: props.initialValue,
  }
);

const validationListeners = {
  blur: (evt) => handleBlur(evt, true),
  change: handleChange,
  input: (evt) => handleChange(evt, !!errorMessage.value),
};
</script>

<style lang="postcss" scoped>
.base-textarea {
  @apply w-full relative;

  label {
    @apply text-sm font-semibold mb-1 block opacity-75 dark:text-zinc-50;
  }

  textarea {
    @apply py-1 px-3 border-zinc-200 border rounded-md w-full resize-none block dark:border-zinc-600 dark:bg-zinc-900 text-zinc-900 dark:text-zinc-50;

    &::placeholder {
      @apply text-zinc-400;
    }

    &:focus,
    &:focus-visible {
      @apply border border-neptune-400 outline-none ring-inset ring-1 ring-neptune-400;
    }
  }

  &.error {
    label {
      @apply text-red-600 dark:text-red-400;
    }

    textarea {
      @apply border-red-600 dark:border-red-400;

      &:focus,
      &:focus-visible {
        @apply ring-red-600 dark:ring-red-400;
      }
    }

    .error-message {
      @apply text-xs mt-1 font-medium text-red-600 dark:text-red-400;
    }
  }
}
</style>
