<template>
  <component :is="chartType" :data="chartData" :options="options" />
</template>

<script setup>
import { computed } from "vue";
import { Line, Bar, Pie } from "vue-chartjs";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Legend,
  Title,
  Tooltip,
  Filler,
  Colors,
} from "chart.js";

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
  rawData: {
    type: Boolean,
    default: false,
    required: false,
  },

  options: {
    type: Object,
    required: false,
    default: () => ({
      responsive: true,
      maintainAspectRatio: false,
    }),
  },
  type: {
    type: String,
    required: false,
    default: "Line",
    validator(value) {
      return ["Line", "Bar", "Pie"].includes(value);
    },
  },
  label: {
    type: String,
    required: true,
  },
});

const chartTypes = {
  Line,
  Bar,
  Pie,
};

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Legend,
  Title,
  Tooltip,
  Filler,
  Colors
);

const chartData = computed(() => {

  if(props.rawData) {
    return props.data;
  }
  return {
    labels: Object.keys(props.data),
    datasets: [
      {
        label: props.label,
        data: Object.values(props.data),
        fill: false,
        tension: 0.1,
      },
    ],
  };
});

const chartType = computed(() => {
  return chartTypes[props.type];
});
</script>
