<template>
  <Table
    :key="paginatedData.length"
    :data="paginatedData"
    :searching="searching"
    :filtering="filtering"
    id="records-table"
  >
    <template #head>
      <tr>
        <th style="width: 10%">
          <div class="flex gap-2">
            {{ $t('table_header_type') }}
            <TableHeaderSort
              v-if="data.length > 1"
              @sorted="(value) => sort('type', value)"
              :currentlyActive="currentSort == 'type' ? ref(true) : ref(false)"
            />
          </div>
        </th>
        <th style="width: 12.5%">
          <div class="flex gap-2">
            {{ $t('table_header_name') }}
            <TableHeaderSort
              v-if="data.length > 1"
              @sorted="(value) => sort('key', value)"
              :currentlyActive="currentSort == 'key' ? ref(true) : ref(false)"
            />
          </div>
        </th>
        <th>{{ $t('table_header_value') }}</th>
        <th class="center" style="width: 12%">Monitor</th>
        <th style="width: 8%">
          <div class="flex gap-2">
            TTL
            <TableHeaderSort
              v-if="data.length > 1"
              :wantAZ="false"
              @sorted="(value) => sort('ttl', value)"
              :currentlyActive="currentSort == 'ttl' ? ref(true) : ref(false)"
            />
          </div>
        </th>
        <th class="end" style="width: 10%">{{ $t('table_header_action') }}</th>
      </tr>
    </template>

    <template #body>
      <template v-for="record in paginatedData" :key="record.id">
        <tr v-if="editFormID === record.id" class="active">
          <!-- Type -->
          <td style="padding-top: 0; padding-bottom: 0">
            {{ record.type }}
            <BaseInput v-if="false" name="type" />
          </td>
          <!-- Name -->
          <td style="padding-top: 0; padding-bottom: 0">
            <div class="-ml-3 pr-2">
              <BaseInput class="py-1" name="key" style="margin-left: -0.5px" />
            </div>
          </td>
          <!-- Value -->
          <td style="padding-top: 0; padding-bottom: 0; align-items: center;" class="flex gap-1">

              <BaseSelect
                v-if="record.type == 'POOL'"
                id="pool"
                name="value"
                :options="poolListOptions"
              />
              <BaseTextarea v-else-if="record.type == 'TXT'" name="value" :placeholder="$t('placeholder_value')" class="my-1" style="margin-left: -0.5px"/>
              <BaseInput v-else class="py-1" name="value" style="margin-left: -0.5px" />
              <div v-if="record.priority">
                <label class="text-sm"></label>
                <BaseInput  id="priority" name="priority" :initialValue="record.priority"
                  :placeholder="$t('placeholder_priority')" type="number"  min="0" max="65535" class="flex max-w-[100px]" style="margin-left: -0.5px; align-items: center" />
              </div>
          </td>
          <td class="center" style="padding-top: 0; padding-bottom: 0"></td>
          <!-- TTL -->
          <td style="padding-top: 0; padding-bottom: 0">
            <div class="-ml-3 pr-2">
              <BaseSelect
                id="ttl"
                name="ttl"
                :options="ttlList"
                :initialValue="record.ttl"
              />
            </div>
          </td>
          <!-- Action -->
          <td style="padding-top: 0; padding-bottom: 0">
            <div class="flex">
              <div class="flex items-center justify-end w-full gap-2">
                <BaseButton
                  icon="close"
                  :tooltip="$t('text_cancel')"
                  size="xs"
                  variant="secondary"
                  @click="handleEditClick(record)"
                />
                <BaseButton
                  :disabled="!meta.valid"
                  icon="done"
                  :tooltip="$t('text_save')"
                  size="xs"
                  variant="success"
                  @click="handleUpdate(record.id)"
                />
              </div>
            </div>
          </td>
        </tr>
        <tr v-if="editFormID !== record.id">          
          <td>{{ record.type }}</td>
          <td>
            <span class="font-semibold">{{ record.key }}</span>
          </td>
          <td class="max-w-md">
            <!-- <button
              class="font-bold text-neptune-500 hover:text-neptune-600 dark:text-neptune-400 dark:hover:text-neptune-300 cursor-pointer"
              v-if="expandableColumns.includes(record.id)"
              @click="handleColumnExpand(record.id)"
            >
              {{ expandedColumn == record.id ? "Less" : "More" }}
            </button>
            {{ record.priority }}
            {{ record.type == "POOL" ? getPoolName(record.value) : record.value }} -->
            <div class="flex">
              <div
                :class="{
                  'whitespace-nowrap overflow-hidden text-ellipsis':
                    expandedColumn != record.id,
                }"
                class="break-all"
                :ref="(el) => (valueColumn[record.id] = el)"
              >
                {{ record.priority }}
                {{ record.type == "POOL" ? getPoolName(record.value) : record.value }}
              </div>
              <button
                class="ml-1 font-bold text-neptune-500 hover:text-neptune-600 dark:text-neptune-400 dark:hover:text-neptune-300 cursor-pointer"
                v-if="expandableColumns.includes(record.id)"
                @click="handleColumnExpand(record.id)"
              >
                {{ expandedColumn == record.id ? $('text_less') : $('text_more') }}
              </button>
            </div>
          </td>
          <td class="center">
            <div
              v-if="['A', 'AAAA', 'CNAME', 'POOL'].includes(record.type)"
              class="flex items-center justify-center gap-2"
            > 
              <BaseTag
                v-if="record.monitor == null"
                variant="warning"
                size="md"
                :text="$t('label_unexisting')"
              />
              <BaseTag 
                v-else
                :variant="(statusMap(record.monitor?.monitorStatus)).variant"
                :text="$t((statusMap(record.monitor?.monitorStatus).text))"
                size="md"
              />
              <BaseButton
                v-if="record.monitor == null"
                icon="add"
                :tooltip="$t('text_add')"
                size="xxs"
                variant="secondary"
                @click="handleCreateMonitor(record.id)"
              />
              <BaseButton
                v-if="record.monitor"
                icon="chevron_right"
                :tooltip="$t('text_open')"
                size="xxs"
                variant="secondary"
                tag="RouterLink"
                :to="{ name: 'MonitorPage', params: { id: record.monitor?.monitorID } }"
                @click="isModalOpen = true"
              />
            </div>
          </td>
          <td>{{ ttlMinutes(record.ttl) }}</td>
          <td class="end">
            <div class="flex items-center justify-end w-full gap-2">
              <BaseButton
                v-if="checkPermission('edit_zone_record_single')"
                icon="edit"
                :tooltip="$t('text_edit')"
                size="xs"
                variant="secondary"
                :disabled="record.type === 'SOA'"
                @click="handleEditClick(record)"
              />
              <BaseButton
                v-if="checkPermission('delete_zone_record_single')"
                icon="delete"
                :tooltip="$t('text_delete')"
                size="xs"
                variant="danger"
                @click="handleDeleteRecord(record._id)"
              />
            </div>
          </td>
        </tr>
      </template>
    </template>
  </Table>
  <Pagination :items-count="data.length" :pagination="pagination" />
  <AlertMessage v-if="error" status="error">{{ error }}</AlertMessage>
</template>

<script setup>
import { ref, markRaw, computed, watch, reactive } from "vue";
import * as yup from "yup";
import { useForm } from "vee-validate";
import { useZonesStore } from "@/stores/ZonesStore";
import { useModalStore } from "@/stores/ModalStore";
import { useAuthStore } from "@/stores/AuthStore";
import TableHeaderSort from "../ui/TableHeaderSort.vue";
import _ from "lodash";
import { isV4, isV6 } from "@/utilities/helpers";
import MonitorForm from "@/components/monitors/MonitorForm.vue";
import ConfirmModal from "@/components/ui/ConfirmModal.vue";
import Pagination from "@/components/ui/Pagination.vue";
import useToastStore from "@/stores/toast";
import { i18n } from "@/i18n";
const { t } = i18n.global;

const { ttlList } = useZonesStore();

const { addToast } = useToastStore();
const {checkPermission} = useAuthStore();
const monitorForm = markRaw(MonitorForm);



const statusMap = (status) => {
  if (!status) return {variant: "secondary", text: "label_created"}
  else {
    switch (status) {
      case "healthy":
        return {variant: "success", text: "label_healthy"};
      case "unhealthy":
        return {variant: "danger", text: "label_unhealthy"};
      case "waring":
        return {variant: "warning", text: "label_warning"};
      case "standby":
        return {variant: "secondary", text: "label_standby"};
      default:
        return {variant: "warning", text: "label_unkonwn"}
    }
  }
}



const props = defineProps({
  data: {
    type: Array,
    default: () => [],
  },
  searching: Boolean,
  filtering: Boolean,
  count: Number,
});

const filteredData = ref(props.data);
const currentSort = ref(null);

watch(
  () => props.data,
  () => {
    filteredData.value = props.data;
    currentSort.value = null;
  }
);

const pagination = reactive({
  currentPage: 1,
  perPage: 20,
  totalPages: computed(() => Math.ceil(filteredData.value.length / pagination.perPage)),
});



const paginatedData = computed(() => {
  const { currentPage, perPage } = pagination;
  const start = (currentPage - 1) * perPage;
  const stop = start + perPage;

  return filteredData.value.slice(start, stop)/* .map((record) => {
    let tmp = record;
    if(["MX", "SRV"].includes(record.type)){
      let split = record.value.split(" ");
      tmp = {...record, priority: split[0], value: split[1]};
    }
    return tmp;
  }); */
});

watch(
  () => pagination.totalPages,
  () => (pagination.currentPage = 1)
);

function handleEditClick({ id, type, value, key, ttl }) {
  expandedColumn.value = null;
  editFormID.value = editFormID.value === id ? null : id;
  setValues({ type, value, key, ttl });
  if (error.value) error.value = "";
}
const editFormID = ref(null);

const schema = yup.object({
  type: yup.string().required(t('validation_required_field')),
  key: yup
    .string()
    .nullable(true)
    .notRequired()
    .max(60, t('validation_message_max_string') + '60')
    .when("type", {
      is: "SRV",
      then: (schema) =>
        schema
          .required(t('validation_required_field'))
          .matches(
            /^(?:[a-z0-9_.-]+|@)$/,
            t('validation_message_srv_name_required_record')
          ),
    })
    .test(
      "is-valid-or-empty",
      t('validation_message_srv_name_record'),
      (value) => {
        return value === "" || /^(?:[a-z0-9_.-]*|@)$/.test(value);
      }
    ),
  ttl: yup.string().when("type", {
    is: (val) => !["POOL"].includes(val),
    then: (schema) => schema.required(t('validation_required_field')),
  }),
  pool: yup.string(),
  description: yup.string().max(256, t('validation_message_max_string') + '256'),
  priority: yup
    .number()
    .typeError( t('validation_number_type') )
    .min(0, t('validation_message_min_value') + '0')
    .max(65535, t('validation_message_max_value') + '65535')
    .when("type", {
      is: "MX",
      then: (schema) => schema.required(t('validation_required_field')),
    }),
  value: yup
    .string()
    .when("type", {
      is: (val) => !["POOL"].includes(val),
      then: (schema) => schema.required(t('validation_required_field')),
    })
    .when("type", {
      is: (val) => ["TXT", "SPF"].includes(val),
      then: (schema) =>
        schema
          .required(t('validation_required_field'))
          .max(256, t('validation_message_max_string') + '256'),
    })
    .when("type", {
      is: "A",
      then: (schema) =>
        schema.test("is-valid-IPv4", t('validation_message_invalid_input') + 'IPv4', (value) => {
          return isV4(value);
        }),
    })
    .when("type", {
      is: "AAAA",
      then: (schema) =>
        schema.test("is-valid-IPv6",  t('validation_message_invalid_input') + 'IPv6', (value) => {
          return isV6(value);
        }),
    })
    .when("type", {
      is: "CNAME",
      then: (schema) =>
        schema
          .matches(/\.$/, t('validation_message_invalid_cname'))
          .max(250, t('validation_message_max_string') + '250'),
    }),
});

const { values, setValues, meta } = useForm({
  validationSchema: schema,
});

const modalStore = useModalStore();
const zoneStore = useZonesStore();

const poolListOptions = computed(() => {
  return (
    zoneStore.poolList.map((pool) => {
      return { value: pool.id, label: pool.name };
    }) || []
  );
});

function getPoolName(poolID) {
  const pool = zoneStore.poolList.find((pool) => pool.id === poolID);
  return pool ? pool.name : "";
}

const error = ref("");
async function handleUpdate(recordID) {
  
  try {
    await zoneStore.updateRecord(zoneStore.zone.id, recordID, { ...values });
    editFormID.value = null;
  } catch (err) {
    addToast({
      title: t('text_update_failure') + ' record',
      content: err,
      variant: "danger",
    });
  }
}

async function handleDeleteRecord(recordID) {
  const error = ref("");
  try {
    await modalStore.openConfirmModal({
      title: t('text_delete') + " Record",
      component: ConfirmModal,
      props: {
        error,
      },
    });
    await zoneStore
      .deleteRecord(zoneStore.zone.id, recordID)
      .then(async () => {
        addToast({
          title: "Record " + t('text_delete_generic_success'),
          variant: "success",
        });
        await zoneStore.refreshData(zoneStore.zone.id);
      })
      .catch((error) => {
        addToast({
          title: t('text_delete_generic_failure') + " record",
          content: error,
          variant: "danger",
        });
      });
    modalStore.closeModal();
  } catch (err) {
    error.value = err;
  }
}

function handleCreateMonitor(recordID) {
  modalStore.openModal({
    title: t('text_create') + " Monitor",
    component: monitorForm,
    props: {
      recordID: recordID,
    },
  });
}

function sort(column, direction) {
  if (!direction) {
    filteredData.value = props.data;
    return;
  }

  currentSort.value = column;

  filteredData.value = _.orderBy(props.data, column, direction);
}

function ttlMinutes(ttl) {
  return ttlList.find((t) => t.value == ttl)?.label || ttl;
}

const valueColumn = ref({});
const expandedColumn = ref(null);

const expandableColumns = computed(() => {
  const columns = paginatedData.value.filter((data) => canExpandColumn(data.id));

  return columns.map((col) => {
    return col.id;
  });
});

function handleColumnExpand(id) {
  expandedColumn.value = expandedColumn.value == id ? null : id;
}

function canExpandColumn(id) {
  return valueColumn.value[id]
    ? valueColumn.value[id].scrollWidth > valueColumn.value[id].offsetWidth
    : false;
}
</script>
