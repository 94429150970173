import BaseService from "../../BaseService";

export default class Translation extends BaseService {
    async readTranslations(locale) {
        const url = `/${this._resource}${locale ? '?lang=' + locale : ''}`;
        const { data } = await this._apiClient.get(url);
        return data;
    }

}
