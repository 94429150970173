<template>
  <div class="space-y-4">
    <p>{{ $t('text_disable_pop_confirm') }}</p>
    <p>
      {{ $t('text_enter') }} "<strong>{{ code }}</strong
      >" {{ $t('text_fill_field_below') }}
    </p>
    <BaseInput
      name="disable"
      @update:value="(val) => (value = val)"
      :value="value"
    />
    <div class="flex gap-x-4 justify-center">
      <BaseButton @click="handleDisable()" :disabled="value !== code"
        >{{ ($t('button_disable')).toUpperCase() }}</BaseButton
      >
    </div>
    <AlertMessage v-if="error" status="error">{{ error }}</AlertMessage>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useClusterStore } from "@/stores/ClusterStore";

const emit = defineEmits(["closeModal"]);

const props = defineProps({
  clusterID: Number,
  code: String,
});

const error = ref("");
const value = ref("");

const clusterStore = useClusterStore();

async function handleDisable() {
  try {
    await clusterStore.disablePop(props.clusterID, props.code, {
      status: false,
    });
    emit("closeModal");
  } catch (err) {
    error.value = err;
  }
}
</script>
