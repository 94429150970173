<template>
  <BaseBox class="space-y-4">
    <h2 class="text-2xl font-bold">{{ $t('title_firewall_rules') }}</h2>
    <p>{{ $t('text_firewall_rule_desc') }}</p>

    <Table :data="clusterStore.firewallRules">
      <template #head>
        <tr>
          <th>Status</th>
          <th>{{ $t('table_header_name') }}</th>
          <th>{{ $t('table_header_type') }}</th>
          <th>{{ $t('table_header_protocol') }}</th>
          <th>{{ $t('table_header_port') }}</th>
          <th>IP</th>
          <th class="end">{{ $t('table_header_action') }}</th>
        </tr>
      </template>
      <template #body>
        <template v-for="rule in clusterStore.firewallRules" :key="rule.id">
          <tr>
            <td>
              <BaseTag :variant="rule.active ? 'success' : 'warning'" size="md"
                :text="rule.active ? $t('text_active') : $t('text_inactive')" />
            </td>
            <td>
              {{ rule.name }}
            </td>
            <td>
              {{ rule.type }}
            </td>
            <td>
              {{ portMap[rule.port] || 'CUSTOM' }}
            </td>
            <td>
              {{ rule.port }}
            </td>
            <td>
              {{ rule.cidr_acl }}
            </td>
            <td class="end">
              <BaseButton v-if="checkPermission('delete_firewall_rule')" class="!inline-flex" icon="delete" tooltip="Delete" size="xs" variant="danger"
                @click="handleDeleteRule(rule.id)" />
            </td>
          </tr>
        </template>
      </template>

      <template #no-data>
        <p class="text-sm text-center font-semibold">
          {{ $t('text_firewall_rule_table_empty') }}
        </p>
      </template>
    </Table>
    <AlertMessage v-if="error" status="error">{{ error }}</AlertMessage>
    <div v-if="checkPermission('create_cluster_firewall_rule')" class="mt-4 flex justify-end">
      <BaseButton variant="primary" @click="
      modalStore.openModal({
        title: $t('text_title_firewall_rule_add'),
        component: AddFirewallRule,
      })
      ">
        {{ $t('text_title_firewall_rule_add') }}
      </BaseButton>
    </div>
  </BaseBox>
</template>

<script setup>
import { ref } from "vue";
import { useClusterStore } from "@/stores/ClusterStore";
import { useModalStore } from "@/stores/ModalStore";
import DeleteFirewallRule from "@/components/clusters/DeleteFirewallRule.vue";
import AddFirewallRule from "@/components/clusters/AddFirewallRule.vue";

import { useAuthStore } from "@/stores/AuthStore";

const authStore = useAuthStore();
const { checkPermission } = authStore;


const error = ref("");

const portMap = {
  "179": "BGP",
  "5999": "CVSup",
  "53": "DNS",
  "853": "DNS over TLS",
  "21": "FTP",
  "3000": "HBCI",
  "80": "HTTP",
  "443": "HTTPS",
  "5190": "ICQ",
  "113": "IDENT/AUTH",
  "143": "IMAP",
  "993": "IMAP/S",
  "4500": "IPsec NAT-T",
  "500": "ISAKMP",
  "1701": "L2TP",
  "389": "LDAP",
  "636": "LDAP/S",
  "1755": "MMS/TCP",
  "7000": "MMS/UDP",
  "445": "MS DS",
  "3389": "MS RDP",
  "1512": "MS WINS",
  "1863": "MSN",
  "119": "NNTP",
  "123": "NTP",
  "137": "NetBIOS-NS",
  "138": "NetBIOS-DGM",
  "139": "NetBIOS-SSN",
  "1194": "OpenVPN",
  "110": "POP3",
  "995": "POP3/S",
  "1723": "PPTP",
  "1812": "RADIUS accounting",
  "5004": "RTP",
  "5060": "SIP",
  "25": "SMTP",
  "465": "SMTP/S",
  "161": "SNMP",
  "162": "SNMP-Trap",
  "22": "SSH",
  "3478": "STUN",
  "587": "SUBMISSION",
  "514": "Syslog",
  "3544": "Teredo",
  "23": "Telnet",
  "69": "TFTP",
  "5900": "VNC"
}

const clusterStore = useClusterStore();
const modalStore = useModalStore();

function handleDeleteRule(id) {
  modalStore.openModal({
    title: "Delete Firewall Rule",
    component: DeleteFirewallRule,
    props: {
      clusterID: clusterStore.cluster.id,
      firewallID: id,
    },
  });
}
</script>
