<template>
    <div class="text-center text-xs font-semibold opacity-60" v-if="pagination.totalPages > 0">
    {{ pagination.perPage * pagination.currentPage - (pagination.perPage - 1) }}-{{
      pagination.perPage * pagination.currentPage < itemsCount
        ? pagination.perPage * pagination.currentPage
        : itemsCount
    }} of {{ itemsCount }}
  </div>
  <div class="pagination" v-if="pagination.totalPages > 0">
    <paginate
      v-model="pagination.currentPage"
      :page-count="pagination.totalPages"
      prev-text='<span class="material-symbols-rounded">chevron_left</span>'
      next-text='<span class="material-symbols-rounded">chevron_right</span>'
      :class="mode"
    />
  </div>
</template>

<script setup>
import Paginate from "vuejs-paginate-next";
import { storeToRefs } from "pinia";
import { useUserStore } from "@/stores/UserStore";
const userStore = useUserStore();
const { mode } = storeToRefs(userStore);

const props = defineProps({
  pagination: Object,
  itemsCount: Number
});
</script>

<style lang="postcss" scoped>
:deep() {
  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    .page-item {
      .page-link {
        width: 1.625rem;
        height: 1.625rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        cursor: pointer;

        @apply text-zinc-700 bg-zinc-100 hover:bg-zinc-200 text-sm font-bold;
      }

      &.active {
        .page-link {
          pointer-events: none;
          @apply text-neptune-700 bg-neptune-100 text-sm font-bold;
        }
      }

      &.disabled {
        .page-link {
          pointer-events: none;
          @apply opacity-50;
        }
      }
    }

    &.dark {
      .page-item {
        .page-link {
          @apply text-zinc-100 bg-zinc-700 hover:bg-zinc-600;
        }

        &.active {
          .page-link {
            @apply text-neptune-100 bg-neptune-800 text-sm font-bold;
          }
        }
      }
    }
  }
}
</style>