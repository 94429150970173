import { defineStore } from "pinia";

/**
 * Define initial state data.
 */
const initialState = {
  toastList: [],
};

export default defineStore("toastStore", {
  state: () => ({ ...initialState }),
  actions: {
    /**
     * Add toast to list.
     * @param {Object} toast
     */
    addToast(toast) {
      toast.id = Date.now().toString();
      this.toastList.unshift(toast);

      setTimeout(() => {
        this.removeToast(toast.id);
      }, 4000);
    },
    /**
     * Remove toast from list.
     * @param {String} id
     */
    removeToast(id) {
      this.toastList = this.toastList.filter((elm) => elm.id !== id);
    },
  },
});
