import BaseService from "../../BaseService";

export default class Cluster extends BaseService {
  async enablePop(clusterID, popCode) {
    const { data } = await this._apiClient.post(
      `/${this._resource}/${clusterID}/pop/${popCode}`,
      { active: true }
    );
    return data;
  }
  async disablePop(clusterID, popCode) {
    const { data } = await this._apiClient.post(
      `/${this._resource}/${clusterID}/pop/${popCode}`,
      { active: false }
    );
    return data;
  }
  async readFirewallRules(clusterID) {
    const { data } = await this._apiClient.get(
      `/${this._resource}/${clusterID}/firewall`
    );
    return data;
  }
  async updateFirewallRule(payload, clusterID, firewallID) {
    const { data } = await this._apiClient.post(
      `/${this._resource}/${clusterID}/firewall/${firewallID}`,
      payload
    );
    return data;
  }
  async createFirewallRule(payload, clusterID) {
    const { data } = await this._apiClient.post(
      `/${this._resource}/${clusterID}/firewall`,
      payload
    );
    return data;
  }
  async deleteFirewallRule(clusterID, firewallID) {
    const { data } = await this._apiClient.post(
      `/${this._resource}/${clusterID}/firewall/${firewallID}/delete`
    );
    return data;
  }
  async readBackups(clusterID) {
    const { data } = await this._apiClient.get(
      `/${this._resource}/${clusterID}/backup`
    );
    return data;
  }
  async downloadBackup(clusterID, backupID) {
    const { data } = await this._apiClient.get(
      `/${this._resource}/${clusterID}/backup/${backupID}/download`
    );
    return data;
  }
  async readConfigurationFileList(clusterID) {
    const { data } = await this._apiClient.get(
      `/${this._resource}/${clusterID}/configuration-file`
    );
    return data;
  }
  async readConfigurationFileData(clusterID, configurationFileName) {
    const { data } = await this._apiClient.get(
      `/${this._resource}/${clusterID}/configuration-file/${configurationFileName}`
    );
    return data;
  }
  async updateConfigurationFileData(payload, clusterID, configurationFileName) {
    const { data } = await this._apiClient.post(
      `/${this._resource}/${clusterID}/configuration-file/${configurationFileName}`,
      payload
    );
    return data;
  }
  async readConfigurationFileHistory(clusterID, configurationFileName, date) {
    const { data } = await this._apiClient.get(
      `/${this._resource}/${clusterID}/configuration-file/${configurationFileName}/${date}`
    );
    return data;
  }
  async readConfiguration(clusterID) {
    const { data } = await this._apiClient.get(
      `/${this._resource}/${clusterID}/configuration`
    );
    return data;
  }
  async updateConfiguration(payload, clusterID) {
    const { data } = await this._apiClient.post(
      `/${this._resource}/${clusterID}/configuration`,
      payload
    );
    return data;
  }
}
