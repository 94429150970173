import { defineStore } from "pinia";
import { zone } from "@/api/base";
import { useUserStore } from "@/stores/UserStore";
import { base64ToBlob } from "@/utilities/helpers";
import { useMonitorsStore } from "@/stores/MonitorsStore";

export const useZonesStore = defineStore("ZonesStore", {
  // state
  state: () => ({
    zones: [],
    zone: {},
    recordList: [],
    poolList: [],
    currentNS: [],
    expectedNS: [],
    ttlList: [
      { value: 20, label: "20 sec" },
      { value: 30, label: "30 sec" },
      { value: 45, label: "45 sec" },
      { value: 60, label: "1 min" },
      { value: 120, label: "2 min" },
      { value: 300, label: "5 min" },
      { value: 600, label: "10 min" },
      { value: 700, label: "12 min" },
      { value: 900, label: "15 min" },
      { value: 1800, label: "30 min" },
      { value: 3600, label: "1 hour" },
      { value: 7200, label: "2 hours" },
      { value: 18000, label: "5 hours" },
      { value: 43200, label: "12 hours" },
      { value: 86400, label: "1 day" },
    ]
  }),
  // getters
  getters: {
    getLastZones: (state) => {
      return (n = 5) => state.zones.slice(0, n);
    },
    getPoolZoneById: (state) => {
      return (id) => state.poolList.find((pool) => pool.id == id);
    },
  },
  // actions
  actions: {
    async refreshData(id = '', monitors = true) {
      const { fetchMonitors } = useMonitorsStore();
      if (id) await this.fetchZone(id)
      else await this.fetchZones();
      if (monitors) await fetchMonitors();
    },
    async fetchZones(payload) {
      const { zoneList } = await zone.readAllByOrganizationID(payload);
      this.zones = zoneList;
    },
    async fetchZone(id) {
      const { zone: zoneData, recordList, poolList } = await zone.read(id);
      this.zone = zoneData;
      this.recordList = recordList;
      this.poolList = poolList.map((pool) => ({
        ...pool,
        monitoring: {
          ...pool.monitoring,
          allowedStates: pool.monitoring?.allowedStates.join(","),
        },
      }));
      return zoneData;
    },
    async createZone(payload) {
      payload.organizationID = useUserStore().user.organizationID;
      const { zone: zoneData } = await zone.create(payload);
      this.zones.unshift(zoneData);
    },
    async validateZone(id) {
      const { currentNS, expectedNS, zone: zoneUpdated } = await zone.readZoneConfigurationsNS(id);
      this.currentNS = currentNS;
      this.expectedNS = expectedNS;
      this.zone = zoneUpdated || this.zone;
      return { currentNS, expectedNS };
    },
    async deleteZone(id) {
      await zone.delete(id);
      this.zones = this.zones.filter((elm) => elm.id !== id);
    },
    async toggleEnableZone(id, payload) {
      await zone.toggleEnableZone(id, payload);
      this.zone.disabled = payload;
    },
    async addRecord(zoneID, payload) {
      const { record } = await zone.createRecord(zoneID, payload);
      this.recordList.push(record);

    },
    async updateRecord(zoneID, recordID, payload) {
      const { record } = await zone.updateRecord(zoneID, recordID, payload);
      this.recordList = this.recordList.map((elm) =>
        elm.id == record.id ? record : elm
      );

    },
    async addPool(zoneID, payload) {
      const { pool } = await zone.createPool(zoneID, payload);
      this.poolList.push(pool);
    },
    async updatePool(zoneID, poolID, payload) {
      const { pool } = await zone.updatePool(zoneID, poolID, payload);
      this.poolList = this.poolList.map((elm) =>
        elm.id == pool.id ? pool : elm
      );
    },
    async deletePool(zoneID, poolID) {
      await zone.deletePool(zoneID, poolID);
      this.poolList = this.poolList.filter((elm) => elm.id !== poolID);
    },
    async deleteRecord(zoneID, recordID) {
      await zone.deleteRecord(zoneID, recordID);
      this.recordList = this.recordList.filter((elm) => elm.id !== recordID);
      this.zones
        .find((zone) => zone.id == zoneID)
        .recordList.filter((elm) => elm.id !== recordID);
    },
    async exportData(zoneID, domain) {
      let { base64data, format: type } = await zone.exportData(zoneID);
      if (base64data.startsWith("77u/"))
        base64data = base64data.replace("77u/", "");
      const binaryData = atob(base64data);
      const blob = new Blob([binaryData], { type });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `zone-${domain}.csv`;
      a.click();
      URL.revokeObjectURL(url);
    },
    async importData(zoneID, base64data) {
      const { recordList } = await zone.importData(zoneID, {
        file: base64data,
      });
      this.recordList = recordList;
      await this.fetchZone(zoneID);
    },

    async exportZones() {
      try {
        const { zones, fileName } = await zone.exportZones();
        const newBlob = await base64ToBlob(zones, "application/zip");
        // Create a temporary URL to download the file
        const url = window.URL.createObjectURL(newBlob);
        // Create a temporary link and click it to trigger the download
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName); // Set the filename
        document.body.appendChild(link);
        link.click();
        // Clean up
        window.URL.revokeObjectURL(url);
      }
      catch (err) {
        console.log(err);
      }
    },

    async importZones(zoneID, base64data) {
      const payload = {
        file: base64data
      }
      await this.fetchZone(zoneID);
    },


  },
});
