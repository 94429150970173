<template>
  <p v-if="props.isEditing" class="text-md font-semibold mb-4">
    {{ initialValues?.endpointPoolID ? $t('text_edit_monitor_click') : null }}
    <a v-if="initialValues?.endpointPoolID"
      class="font-bold text-neptune-500 hover:text-neptune-600 dark:text-neptune-300 dark:hover:text-neptune-400 cursor-pointer"
      @click="handleNavigate">
      {{ $t('text_here') }}
    </a>
  </p>
  <p v-else class="text-md font-semibold mb-4"> {{ $t('text_create_new_monitor') }}</p>
  <form class="space-y-4" :class="{ 'pointer-events-none': initialValues?.endpointPoolID }" @submit="onSubmit">
    <BaseRadio name="type" :options="[
    { label: 'PING', value: 'ping' },
    { label: 'HTTP', value: 'http' },
  ]" />
    <div class="flex gap-4">
      <BaseInput id="interval" :label="$t('label_check_interval')" name="interval" :placeholder="$t('placeholder_seconds')" type="number"
        min="20" max="600" />
      <BaseInput id="checkToFail" :label="$t('label_max_fails')" name="checkToFail" :placeholder="$t('placeholder_number')" type="number" min="3"
        max="10" />
    </div>
    <div v-show="values.type == 'http'" class="pt-4 space-y-2">
      <h4 class="text-md font-semibold">{{ $t('text_advanced_settings') }}</h4>
      <BaseInput id="port" :label="$t('label_port')" name="port" :placeholder="$t('placeholder_port')" type="number" min="0" />
      <h5 class="text-sm font-semibold">{{ $t('text_keyword_present') }}</h5>
      <div class="flex gap-4">
        <div>
          <div class="text-sm">{{ $t('text_enable') }}</div>
          <BaseSwitch v-model="advancedSettings.needKeywordEnabled" />
        </div>
        <BaseInput v-if="advancedSettings.needKeywordEnabled" id="needKeyword"
          :label="$t('label_present_keywords')" name="needKeyword" :placeholder="$t('placeholder_keyword')"
          type="text" />
      </div>
      <h5 class="text-sm font-semibold">{{ $t('text_keyword_not_present') }}</h5>
      <div class="flex gap-4">
        <div>
          <div class="text-sm">{{ $t('text_enable') }}</div>
          <BaseSwitch v-model="advancedSettings.dontNeedKeywordEnabled" />
        </div>
        <BaseInput v-if="advancedSettings.dontNeedKeywordEnabled" id="notNeedKeyword"
          :label="$t('label_present_keywords')" name="notNeedKeyword" :placeholder="$t('placeholder_keyword')"
          type="text" />
      </div>
      <h5 class="text-sm font-semibold">{{ $t('label_accepted_states') }}</h5>
      <div class="flex gap-4">
        <div>
          <div class="text-sm">{{ $t('text_enable') }}</div>
          <BaseSwitch v-model="advancedSettings.allowedStatesEnabled" />
        </div>
        <BaseInput v-if="advancedSettings.allowedStatesEnabled" id="allowedStates"
          :label="$t('label_add_status_code_message')" name="allowedStates" placeholder="200, 201" type="text" />
      </div>
      <h5 class="text-sm font-semibold">{{ $t('text_max_response_time') }}</h5>
      <div class="flex gap-4">
        <div>
          <div class="text-sm">{{ $t('text_enable') }}</div>
          <BaseSwitch v-model="advancedSettings.maxTimeEnabled" />
        </div>
        <BaseInput v-if="advancedSettings.maxTimeEnabled" id="maxTime"
          :label="$t('label_max_time_enabled')" name="maxTime" placeholder="500"
          type="number" />
      </div>
    </div>
    <div :class="['flex', (isEditing ? 'justify-between' : 'justify-end')]">
      <div class="text-end mt-4" v-if="initialValues?.endpointPoolID == null && props.isEditing">
        <BaseButton size="sm" variant="danger" @click.prevent="handleDelete"> {{ $t('button_delete') }} </BaseButton>
      </div>
      <div class="text-end mt-4" v-if="initialValues?.endpointPoolID == null">
        <BaseButton size="sm" formnovalidate :disabled="isSubmitting || !meta.valid">{{
    isEditing ? $t("text_save") : $t("button_create")
  }}</BaseButton>
      </div>
    </div>
    <AlertMessage v-if="error" status="error">{{ error }}</AlertMessage>
    <AlertMessage v-if="success" status="success">
      {{ "Monitor " +  isEditing ?  + $t("text_update_generic_success") : $t("text_create_generic_success")}}
    </AlertMessage>
  </form>
</template>

<script setup>
import { ref } from "vue";
import * as yup from "yup";
import { useForm } from "vee-validate";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";
import { useMonitorsStore } from "@/stores/MonitorsStore";
import { useUserStore } from "@/stores/UserStore";
import { useZonesStore } from "@/stores/ZonesStore";
import useToastStore from "@/stores/toast";

import { i18n } from "@/i18n";
const { t } = i18n.global;

const userStore = useUserStore();
const zonesStore = useZonesStore();
const router = useRouter();

const { user } = storeToRefs(userStore);
const { zone } = storeToRefs(zonesStore);
const { addToast } = useToastStore();

const props = defineProps({
  id: {
    type: [Number, String],
  },
  initialValues: {
    type: Object,
    default: () => ({
      type: "ping",
      interval: 20,
      checkToFail: 3,
    }),
  },
  isEditing: {
    type: Boolean,
    default: false,
  },
  recordID: {
    type: String,
    default: null
  }
});


const handleNavigate = () => {
  router.push({ path: `/zones/${props.initialValues?.zoneID}/pool/${props.initialValues?.poolID}/edit` })
  emit("closeModal");
};

const emit = defineEmits(["closeModal"]);

const { needKeyword, notNeedKeyword, allowedStates, maxTime } = props.initialValues;

const error = ref(false);
const success = ref(false);

const advancedSettings = ref({
  needKeywordEnabled: needKeyword ? true : false,
  dontNeedKeywordEnabled: notNeedKeyword ? true : false,
  allowedStatesEnabled: allowedStates?.length ? true : false,
  maxTimeEnabled: maxTime ? true : false,
});

const schema = yup.object({
  type: yup.string().required(),
  port: yup
    .number()
    .min(0, t('validation_message_gte_than') + '0')
    .nullable(true)
    .transform((value, originalValue) => {
      if (originalValue === '') return null;
      return value;
    }),
  needKeyword: yup.string().nullable(true),
  notNeedKeyword: yup.string().nullable(true),
  maxTime: yup
    .number()
    .min(0, t('validation_message_gte_than') + '0')
    .max(500,t('validation_message_lte_than') + '500')
    .nullable(true)
    .default(0)
    .transform((value, originalValue) => {
    if (originalValue === '') return null;
    return value;
  }),
  interval: yup
    .number()
    .transform((v, o) => (o === "" ? null : v))
    .min(20, t('validation_message_gte_than') + '20')
    .max(600,t('validation_message_lte_than') + '600')
    .nullable(true),
  checkToFail: yup
    .number()
    .transform((v, o) => (o === "" ? null : v))
    .min(3, t('validation_message_gte_than') + '3')
    .max(10, t('validation_message_gte_than') + '10')
    .nullable(true),
  allowedStates: yup.lazy((val) =>
    Array.isArray(val)
      ? yup.array().of(yup.string().matches(/^(\d+(,\d+)*)?$/, t('text_states_comma_separated')))
      : yup.string().matches(/^(\d+(,\d+)*)?$/, t('validation_number_type'))
  ),
});

const { isSubmitting, handleSubmit, meta, values } = useForm({
  validationSchema: schema,
  initialValues: props.initialValues,
});

if (props.isEditing && !props.initialValues?.allowedStates?.length) {
  props.initialValues.allowedStates = "";
}

const monitorsStore = useMonitorsStore();

const onSubmit = handleSubmit.withControlled(async (form) => {
  form.allowedStates = form.allowedStates?.length ? form.allowedStates.split(",") : [];

  try {
    if (props.isEditing) {
      if (values.clusterID) form.clusterID = values.clusterID;
      if (values.endpointPoolID) form.endpointPoolID = values.endpointPoolID;
      if (values.poolID) form.poolID = values.poolID;
      if (values.recordID) form.recordID = values.recordID;
      if (values.zoneID) form.zoneID = values.zoneID;

      await monitorsStore.updateMonitor(form, props.id);

    } else {
      form.zoneID = zone.value.id;
      form.recordID = props.recordID;
      form.organizationID = user.value.organizationID;

      await monitorsStore.addMonitor(form);
    }

    await zonesStore.refreshData(form.zoneID);

    addToast({
      title: `Monitor ${props.isEditing ? t('text_update_generic_success') : t('text_create_generic_success') }`,
      variant: "success",
    });

    emit("closeModal");
  } catch (err) {
    error.value = err;
    addToast({
      title: `Monitor ${props.isEditing ? t('text_update_generic_failure') : t('text_create_generic_failure') }`,
      variant: "danger",
    });
  }
});


async function handleDelete() {
  try {
    error.value = false;
    await monitorsStore.deleteMonitor(props.id);
    success.value = true
    await zonesStore.refreshData(props?.initialValues?.zoneID || '');
    emit("closeModal");
    addToast({
      title: `Monitor ${t('text_delete_generic_success')}`,
      variant: "success",
    });

  }
  catch (err) {
    error.value = err;
    addToast({
      title: t('text_delete_generic_failure') + ' monitor',
      variant: "danger",
    });
  }
}

</script>
