<template>
  <component
    :is="tag"
    class="btn"
    :class="[
      variants[variant],
      size,
      { icon: icon },
      { 'has-tooltip': tooltip },
      { 'tooltip-bottom': tooltip && tooltipPosition == 'bottom' },
    ]"
    :data-tooltip="tooltip"
  >
    <slot></slot>
    <span v-if="icon" class="material-symbols-rounded"> {{ icon }} </span>
  </component>
</template>

<script setup>
const { variant, size } = defineProps({
  variant: {
    type: String,
    default: "primary",
  },
  size: {
    type: String,
    default: "md",
  },
  tag: {
    type: String,
    default: "button",
    validator(value) {
      return ["button", "a", "RouterLink"].includes(value);
    },
  },
  icon: {
    type: String,
    default: "",
  },
  tooltip: {
    type: String,
    default: "",
  },
  tooltipPosition: {
    type: String,
    default: "top",
    validator(value) {
      return ["top", "bottom"].includes(value);
    },
  },
});

const variants = {
  primary: "btn-primary",
  secondary: "btn-secondary",
  warning: "btn-warning",
  success: "btn-success",
  danger: "btn-danger",
};
</script>

<style lang="postcss" scoped>
.btn {
  @apply rounded-lg font-bold relative;

  &.xs {
    @apply py-1 px-2 text-xs;
  }

  &.sm {
    @apply py-2 px-4 text-sm;
  }

  &.md {
    @apply py-2 px-5;
  }

  &:disabled {
    @apply opacity-50 cursor-not-allowed;
  }

  &.btn-primary {
    @apply bg-neptune-400 dark:bg-neptune-500 text-white;

    &:hover {
      @apply bg-neptune-500 dark:bg-neptune-600;
    }

    &:active {
      @apply bg-neptune-600;
    }

    &:disabled {
      @apply bg-neptune-200 text-neptune-700;
    }
  }

  &.btn-secondary {
    @apply text-zinc-600 dark:text-zinc-50 bg-zinc-200 dark:bg-zinc-600;

    &:hover {
      @apply bg-zinc-300 dark:bg-zinc-700;
    }

    &:active {
      @apply bg-zinc-700;
    }

    &:disabled {
      @apply bg-zinc-200 text-zinc-700;
    }
  }

  &.btn-success {
    @apply bg-green-500 text-white;

    &:hover {
      @apply bg-green-600;
    }

    &:active {
      @apply bg-green-700;
    }

    &:disabled {
      @apply bg-green-200 text-green-700;
    }
  }

  &.btn-warning {
    @apply bg-yellow-500 text-white;

    &:hover {
      @apply bg-yellow-600;
    }

    &:active {
      @apply bg-yellow-700;
    }

    &:disabled {
      @apply bg-yellow-200 text-yellow-700;
    }
  }

  &.btn-danger {
    @apply bg-red-500 text-white;

    &:hover {
      @apply bg-red-600;
    }

    &:active {
      @apply bg-red-700;
    }

    &:disabled {
      @apply bg-red-200 text-red-700;
    }
  }

  &.text {
    &.xs {
      @apply -my-1 -mx-2;
    }

    &.sm {
      @apply -my-2 -mx-4;
    }

    &.md {
      @apply -my-3 -mx-5;
    }

    @apply bg-transparent;

    &.btn-primary {
      @apply text-neptune-400;

      &:hover {
        @apply text-neptune-500 bg-neptune-50;
      }

      &:active {
        @apply bg-neptune-100;
      }
    }

    &.btn-secondary {
      @apply text-zinc-500;

      &:hover {
        @apply bg-zinc-100;
      }

      &:active {
        @apply bg-zinc-200 text-zinc-600;
      }
    }

    &.btn-success {
      @apply text-green-500;

      &:hover {
        @apply bg-green-50;
      }

      &:active {
        @apply bg-green-100;
      }
    }

    &.btn-danger {
      @apply text-red-500;

      &:hover {
        @apply bg-red-50;
      }

      &:active {
        @apply bg-red-100;
      }
    }
  }

  &.icon {
    @apply flex items-center justify-center p-[3px] rounded-full bg-transparent;

    &.xxs {
      @apply p-[1px];

      .material-symbols-rounded {
        font-size: 1.125rem;
      }
    }

    &.btn-secondary {
      @apply text-zinc-600 dark:text-zinc-50 bg-zinc-200 dark:bg-zinc-600;

      span {
        @apply text-zinc-600 dark:text-zinc-50;
      }

      &:hover {
        @apply bg-zinc-300 dark:bg-zinc-700;
      }
    }

    &.btn-danger {
      @apply text-red-500 bg-red-100 dark:text-red-100 dark:bg-red-500;

      span {
        @apply text-red-500 dark:text-red-100;
      }

      &:hover {
        @apply bg-red-200 dark:bg-red-600;
      }
    }

    &.btn-success {
      @apply bg-green-100 dark:bg-green-700;

      span {
        @apply text-green-600 dark:text-green-100;
      }

      &:hover {
        @apply bg-green-200 dark:bg-green-800;
      }
    }

    .material-symbols-rounded {
      @apply leading-none;
      font-size: 1.25rem;
      font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 20;
    }
  }
  &.has-tooltip {
    &::after {
      display: none;
      content: attr(data-tooltip);
      @apply absolute text-xs font-semibold bg-zinc-600 text-zinc-50 px-1.5 py-0.5 rounded-md dark:bg-zinc-50 dark:text-zinc-600;
      /* background-color: rgba(theme("colors.zinc.800"), 0.7); */
      backdrop-filter: blur(3px);
      text-wrap: nowrap;
      bottom: calc(100% + 0.5rem);
      left: 50%;
      transform: translateX(-50%);
    }
    &.tooltip-bottom {
      &::after {
        bottom: auto;
        top: calc(100% + 0.5rem);
      }
    }

    &:hover {
      &::after {
        display: block;
      }
    }
  }
}
</style>
