<template>
  <div class="msg border-2 p-2 rounded-xl" :class="variants[status]">
    <slot></slot>
  </div>
</template>

<script setup>
defineProps({
  status: String,
});

const variants = {
  success: "success",
  error: "danger",
};
</script>

<style lang="postcss" scoped>
.msg {
  @apply font-semibold;
  position: relative;

  ::deep {
    & * {
      z-index: 1;
      position: relative;
    }
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    opacity: 0.1;

    @apply bg-transparent;
  }

  &.success {
    @apply bg-green-100 dark:bg-zinc-800 border-green-300 dark:border-green-400 text-green-600;

    &::after {
      @apply dark:bg-green-500;
    }
  }

  &.danger {
    @apply bg-red-100 dark:bg-zinc-800 border-red-300 dark:border-red-400 text-red-600;

    &::after {
      @apply dark:bg-red-500;
    }
  }
}
</style>
