<template>
  <div v-if="!error" class="space-y-4">
    <p>{{ $t("title_modal") }}</p>
    <div class="flex gap-x-4 justify-center">
      <BaseButton variant="secondary" @click="$emit('closeModal')"
        >{{ $t("button_cancel") }}</BaseButton
      >
      <BaseButton @click="modalStore.resolveFunc(true)">{{ $t("button_modal_confirm") }}</BaseButton>
    </div>
  </div>
  <AlertMessage v-else class="mt-1" status="error">{{ error }}</AlertMessage>
</template>

<script setup>
import { useModalStore } from "@/stores/ModalStore";
const modalStore = useModalStore();

defineProps({
  error: String,
});
</script>
