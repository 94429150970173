<template>
  <div class="grid grid-cols-12 gap-10">
    <div class="grid grid-cols-12 gap-10 col-span-12">
      <div class="grid grid-cols-1 gap-10 col-span-4">
        <div>
          <BaseBox class="flex flex-col items-center justify-center" @click="router.push({ name: 'MonitorPage' })"
            hover>
            <UptimeCounter :total-down="monitorsStore.getTotalUnhealthyMonitors"
              :total="monitorsStore.getTotalMonitors" />
            <span
              class="top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 absolute material-symbols-outlined text-[10rem] font-semibold flex items-center opacity-5 dark:opacity-[0.03]">
              monitoring
            </span>
          </BaseBox>
        </div>
        <MonitorsPreview />
      </div>
      <div class="grid-cols-1 gap-10 col-span-8">
        <ChartQueries />
      </div>
    </div>
    <div class="grid grid-cols-12 gap-10 col-span-12">
      <div class="grid grid-cols-1 gap-10 col-span-4">
        <BaseBox class="flex flex-col items-center justify-center" @click="router.push({ name: 'ZonesPage' })" hover>
          <div class="text-7xl font-extrabold text-center">{{ zones.length }}</div>
          <div class="opacity-70 text-center">
            <div v-html="$t('text_active_zones')"></div>
          </div>
          <span
            class="top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 absolute material-symbols-outlined text-[10rem] font-semibold flex items-center opacity-5 dark:opacity-[0.03]">
            dns
          </span>
        </BaseBox>
        <BaseBox class="flex flex-col items-center justify-center" @click="router.push({ name: 'PoolPage' })" hover>
          <div class="text-7xl font-extrabold text-center">{{ pools.length }}</div>
          <div class="opacity-70 text-center">
            <div v-html="$t('text_active_pools')"></div>
          </div>
          <span
            class="top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 absolute material-symbols-outlined text-[10rem] font-semibold flex items-center opacity-5 dark:opacity-[0.03]">
            atr
          </span>
        </BaseBox>
        <BaseBox class="flex flex-col items-center justify-center" @click="router.push({ name: 'ClusterPage' })" hover>
          <div class="text-7xl font-extrabold text-center">{{ clusterList.length }}</div>
          <div class="opacity-70 text-center">
            <div v-html="$t('text_active_deeps')"></div>
          </div>
          <span
            class="top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 absolute material-symbols-outlined text-[10rem] font-semibold flex items-center opacity-5 dark:opacity-[0.03]">
            dns
          </span>
        </BaseBox>
      </div>
      <div class="grid grid-cols-1 gap-10 col-span-8">
        <ZonesPreview />
      </div>
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";

import ZonesPreview from "@/components/zones/ZonesPreview.vue";
import MonitorsPreview from "@/components/monitors/MonitorsPreview.vue";
import ChartQueries from "@/components/monitors/ChartQueries.vue";
import UptimeCounter from "../components/monitors/UptimeCounter.vue";
import { useMonitorsStore } from "@/stores/MonitorsStore";

const monitorsStore = useMonitorsStore();

import { useZonesStore } from "@/stores/ZonesStore";
const zonesStore = useZonesStore();
const { zones } = storeToRefs(zonesStore);

import { usePoolsStore } from "@/stores/PoolsStore";
const poolsStore = usePoolsStore();
const { pools } = storeToRefs(poolsStore);

import { useClusterStore } from "@/stores/ClusterStore";
const clusterStore = useClusterStore();
const { clusterList } = storeToRefs(clusterStore);

import router from "@/routes";
</script>
