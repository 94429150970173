<template>
  <div class="base-input" :class="{ error: errorMessage }">
    <div class="flex justify-between items-center mb-1">
      <label class="flex-1" v-if="label" :for="$attrs.id"
        >{{ label }}
        <span class="text-red-600 font-medium dark:text-red-400" v-if="required"
          >*</span
        ></label
      >
      <slot name="label-more"></slot>
    </div>
    <div class="relative">
      <input
        :class="$attrs.class"
        v-bind="$attrs"
        type="file"
        v-on="validationListeners"
      />
    </div>
    <span v-if="errorMessage" class="error-message">{{ errorMessage }}</span>
  </div>
</template>

<script>
// use normal <script> to declare options
export default {
  inheritAttrs: true,
};
</script>

<script setup>
import { useField } from "vee-validate";

defineEmits(["update:value"]);

const props = defineProps({
  type: String,
  name: String,
  label: String,
  initialValue: String,
  required: Boolean,
});

const { value, errorMessage, handleChange } = useField(
  () => props.name,
  undefined,
  {
    validateOnValueUpdate: false,
    initialValue: props.initialValue,
  }
);

const validationListeners = {
  input: (evt) => handleChange(evt, !!errorMessage.value),
};
</script>

<style lang="postcss" scoped>
.base-input {
  @apply w-full relative;

  label {
    @apply text-sm font-semibold block;
  }

  input {
    @apply py-1 px-3 border-zinc-200 border rounded-md w-full dark:border-zinc-600 dark:bg-zinc-900;

    &::placeholder {
      @apply text-zinc-400;
    }

    &:focus,
    &:focus-visible {
      @apply border border-neptune-400 outline-none ring-inset ring-1 ring-neptune-400;
    }
  }

  &.error {
    label {
      @apply text-red-600 dark:text-red-400;
    }

    input {
      @apply border-red-600 dark:border-red-400;

      &:focus,
      &:focus-visible {
        @apply ring-red-600 dark:ring-red-400;
      }
    }

    .error-message {
      @apply text-xs mt-1 font-medium text-red-600 dark:text-red-400;
    }
  }
}
</style>
