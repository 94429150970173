<template>
  <Teleport to="body">
    <div
      class="fixed bottom-5 left-1/2 z-50 flex -translate-x-1/2 flex-col gap-y-3"
    >
      <TransitionGroup name="list">
        <BaseToast
          v-for="toast in toastStore.toastList"
          :key="toast.id"
          :variant="toast.variant"
          @on-closing="toastStore.removeToast(toast.id)"
        >
          <div class="toast-title font-bold">{{ toast.title }}</div>
          <div class="toast-description text-xs mt-1" v-if="toast.content">{{ toast.content }}</div>
        </BaseToast>
      </TransitionGroup>
    </div>
  </Teleport>
</template>

<script setup>
import useToastStore from "@/stores/toast";

const toastStore = useToastStore();
</script>

<style lang="postcss" scoped>
.list-move, 
.list-enter-active,
.list-leave-active {
  transition: all 10s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateY(30px) translateY(0);
  transform-origin: center bottom;
}

.list-leave-active {
  position: absolute;
}
</style>
