<template>
  <span class="sort" @click="sort()" :class="[sortOrder, { active: sortOrder }]">
    <span class="material-symbols-rounded">
      {{ sortOrder == "desc" ? "north" : "south" }}
    </span>
    <span v-if="wantAZ" >AZ</span>
  </span>
</template>

<script setup>
import { ref, watch } from "vue";
const sortOrder = ref(null);
const emit = defineEmits(["sorted"]);

const props = defineProps({
  currentlyActive: [Boolean, Object],
  wantAZ: { type: Boolean, default: true },
});


function sort() {
  if (!sortOrder.value) {
    sortOrder.value = "asc";
  } else if (sortOrder.value == "asc") {
    sortOrder.value = "desc";
  } else if (sortOrder.value == "desc") {
    sortOrder.value = null;
  }

  emit("sorted", sortOrder.value);
}

watch(
  () => props.currentlyActive,
  () => {
    if (!props.currentlyActive.value) {
      sortOrder.value = null;
    }
  }
);
</script>

<style lang="postcss" scoped>
.sort {
  font-size: 0.75rem;
  @apply inline-flex items-center gap-0.5 opacity-30 rounded-xl px-1.5 cursor-pointer;

  .material-symbols-rounded {
    font-size: 1rem;
    font-weight: 600;
    margin-left: -0.2rem;
  }

  &:hover {
    @apply opacity-100;
  }

  &.active {
    @apply opacity-100 bg-neptune-200 dark:bg-neptune-900;
  }
}
</style>
