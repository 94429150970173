import { defineStore } from "pinia";
import { user as userApi } from "@/api/base";

export const useUserStore = defineStore("UserStore", {
  // state
  state: () => ({
    user: {},
    mode: null,
    sidebarCollapsed: false,
  }),
  // getters
  getters: {
    isAdmin: (state) => {
      return state.user.role === "administrator";
    },
  },
  // actions
  actions: {
    addUser(payload) {
      this.user = payload;
    },
    toggleSidebarCollapsed() {
      this.sidebarCollapsed = !this.sidebarCollapsed;
    },
    setLightMode() {
      this.mode = "light";
      document.documentElement.classList.remove("dark");
    },
    setDarkMode() {
      this.mode = "dark";
      document.documentElement.classList.add("dark");
    },
    switchMode() {
      if (this.mode == "light") {
        this.setDarkMode();
      } else {
        this.setLightMode();
      }
    },
    async updateUser(payload, id){
      const { user } = await userApi.update(payload, id);
      this.user = {...this.user, ...user};
      const curr = localStorage.getItem("UserStore");
      const toSet = JSON.stringify({...JSON.parse(curr), user: this.user});
      localStorage.setItem("UserStore", toSet );
    }
  },
  persist: {
    storage: localStorage,
  },
});
