<template>
  <BaseBox class="flex flex-col gap-4">
    <div class="flex items-center justify-between">
      <h3 class="font-bold">{{ $t('text_zones') }}</h3>

      <BaseButton size="xs" variant="primary" type="text" tag="RouterLink" :to="{ name: 'ZonesPage' }">
        {{ $t('button_view_all') }}
      </BaseButton>
    </div>
    <ul class="flex flex-col gap-4" v-if="zonesStore.getLastZones() && zonesStore.getLastZones().length">
      <li v-for="zone in zonesStore.getLastZones()" :key="zone.id" class="pb-4"
        @click="$router.push({ name: 'ZoneSinglePageIndex', params: { id: zone.id } })">
        <div class="zone">
          <h3 class="text-lg font-bold mb-3">
            {{ zone.domain }}
          </h3>
          <ZoneMonitorCounters :zone="zone" />
        </div>
      </li>
    </ul>
    <h3 v-else>{{ $t('text_no_zones') }}</h3>
  </BaseBox>
</template>

<script setup>
import { useZonesStore } from "@/stores/ZonesStore";
import ZoneMonitorCounters from "@/components/zones/ZoneMonitorCounters.vue";

const zonesStore = useZonesStore();
</script>

<style lang="postcss" scoped>
li {
  @apply border-b border-zinc-200 dark:border-zinc-600 relative;
  cursor: pointer;

  .zone {
    @apply relative;

    &::after {
      @apply hidden absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-zinc-600 dark:bg-zinc-50 opacity-5 rounded-lg w-full h-full;
      content: "";
      width: calc(100% + 15px);
      height: calc(100% + 15px);
    }

    * {
      z-index: 1;
    }

    &:hover {
      &::after {
        @apply block;

      }
    }
  }

  &:hover {}

  &:last-child {
    @apply border-0 p-0;
  }
}
</style>
