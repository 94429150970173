<template>
  <h1 class="text-2xl font-bold text-zinc-800 flex gap-x-2 items-center">
    {{ clusterStore.cluster.domain }}
    <BaseTag v-if="clusterStore.cluster.active" variant="success" size="md" text="Active" />
    <BaseTag v-else variant="warning" size="md" text="suspended" />
  </h1>

  <div class="grid grid-cols-12 gap-y-4 md:gap-x-4">
    <div class="col-span-12 md:col-span-6 space-y-4">
      <ClusterInformation :data="{
      ...clusterStore.cluster.details,
      domain: clusterStore.cluster.domain,
    }" />
      <PopList :cluster-id="clusterStore.cluster.id" :pop-list="clusterStore.cluster.popList" />
      <BaseBox class="space-y-4">
        <h2 class="text-2xl font-bold">DNS</h2>
        <div class="flex justify-between items-center">
          <div>
            <p>{{ $t('text_manage_dns_zone') }}</p>
          </div>
          <BaseButton v-if="checkPermission('view_zone_single_page')" tag="RouterLink" :to="{
      name: 'ZoneSinglePageIndex',
      params: { id: clusterStore.cluster.zoneID },
    }">{{ $t('title_manage_zone') }}</BaseButton>
        </div>
      </BaseBox>
      <BaseBox class="space-y-4">
        <h2 class="text-2xl font-bold">{{ $t('title_logs') }}</h2>
        <div class="flex justify-between items-center">
          <div class="max-w-[50%]">
            <p>
              {{ $t('text_description_log_deep') }}
            </p>
          </div>
          <BaseButton target="_blank" tag="a" :href="clusterStore.cluster.logs_link">{{ $t('title_log_platform') }}</BaseButton>
        </div>
      </BaseBox>
      <BaseBox class="space-y-4" v-if="checkPermission('read_cluster_configuration_file_list')">
        <h2 class="text-2xl font-bold">{{ $t('title_web_server_configuration') }}</h2>
        <div class="flex justify-between items-center" v-if="clusterStore.configurationFileList.length">
          <div class="max-w-[50%]">
            <p>{{ $t('text_description_web_server_configuration') }}</p>
          </div>
          <BaseButton tag="RouterLink" :to="{ name: 'ClusterConfigurationFile' }">{{ $t('title_manage_configuration') }}</BaseButton>
        </div>
        <p v-else>{{ $t('text_missing_configuration_files') }}</p>
      </BaseBox>
    </div>
    <div class="col-span-12 md:col-span-6 space-y-4">
      <ClusterQuality v-if="clusterStore.cluster.monitor_id" :monitor-id="clusterStore.cluster.monitor_id" />
      <BaseBox class="space-y-4">
        <div class="flex justify-between">
          <h2 class="text-2xl font-bold">{{ $t('title_statistics') }}</h2>
          <BaseButton size="sm" v-if="checkPermission('view_grafana_page')" >{{ $t('button_open_in') }} Grafana</BaseButton>
        </div>
        <ClusterStatistic v-if="clusterStore.cluster.statistic" :statistic="clusterStore.cluster.statistic" />
      </BaseBox>
      <ClusterSSL :data="clusterStore.cluster.ssl" />
    </div>
    <div class="col-span-12" v-if="checkPermission('read_cluster_firewall_rule_list')">
      <FirewallRules />
    </div>
    <div v-if="checkPermission('read_cluster_backup_list')" class="col-span-12 md:col-span-6">
      <BackupsList />
    </div>
    <div v-if="checkPermission('view_cluster_configuration_page')" class="col-span-12 md:col-span-6">
      <BaseBox class="space-y-4">
        <h2 class="text-2xl font-bold">{{ ($t('title_system_configuration')).toUpperCase() }}</h2>
        <div class="flex justify-between items-center" v-if="clusterStore.configuration?.length">
          <div class="max-w-[50%]">
            <p>{{ $t('text_description_system_configuration') }}</p>
          </div>
          <BaseButton tag="RouterLink" :to="{ name: 'ClusterConfiguration' }">{{ $t('title_manage_configuration') }}</BaseButton>
        </div>
        <p v-else>{{ $t('text_description_system_configurations') }}</p>
      </BaseBox>
    </div>
  </div>
</template>

<script setup>
import { useRoute } from "vue-router";
import { useClusterStore } from "@/stores/ClusterStore";
import { useAuthStore } from "@/stores/AuthStore";

import ClusterInformation from "@/components/clusters/ClusterInformation.vue";
import ClusterQuality from "@/components/clusters/ClusterQuality.vue";
import PopList from "@/components/clusters/PopList.vue";
import ClusterSSL from "@/components/clusters/ClusterSSL.vue";
import FirewallRules from "@/components/clusters/FirewallRules.vue";
import BackupsList from "@/components/clusters/BackupsList.vue";
import ClusterStatistic from "@/components/clusters/ClusterStatistic.vue";


const route = useRoute();
const clusterStore = useClusterStore();
const authStore = useAuthStore();
const { checkPermission } = authStore;

clusterStore.fetchCluster(route.params.id);
clusterStore.fetchConfigurationFileList(route.params.id);
clusterStore.fetchConfiguration(route.params.id);
</script>
