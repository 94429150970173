import { defineStore } from "pinia";
import { virtualMachine } from "@/api/base";

export const useVirtualMachineStore = defineStore("VirtualMachineStore", {
  // state
  state: () => ({
    summary: null,
    details: null,
  }),
  // getters
  getters: {},
  // actions
  actions: {
    async fetchVirtualMachine(code) {
      const { virtualMachineSummary, virtualMachineDetails } =
        await virtualMachine.read(code);
      this.summary = virtualMachineSummary;
      this.details = virtualMachineDetails;
    },
    async powerOn(code) {
      await virtualMachine.powerOn(code);
      this.summary.power_state = "POWERED_ON";
    },
    async powerOff(code) {
      await virtualMachine.powerOff(code);
      this.summary.power_state = "POWERED_OFF";
    },
    async powerReset(code) {
      await virtualMachine.powerReset(code);
    },
  },
});
