<template>
  <div class="uptime">
    <div class="text-7xl font-extrabold text-center" v-if="totalDown > 0">
      <span
        :class="{
          'text-green-500 dark:text-green-400': totalDown == 0,
          'text-red-500 dark:text-red-400': totalDown > 0,
        }"
        >{{ totalDown }}</span
      >
      <span class="text-4xl font-medium ml-0.5 opacity-75">/{{ total }}</span>
    </div>
    <div class="text-7xl font-extrabold text-center" v-else>{{ total }}</div>
    <div
      class="text-red-500 dark:text-red-400 text-center font-semibold"
      v-if="totalDown > 0"
    >
      {{ totalDown }} {{ totalDown > 1 ? $t('text_monitors_down') : $t('text_monitor_down') }}
    </div>
    <!-- <div class="text-center font-semibold text-green-500 dark:text-green-400" v-else>All monitors are up!</div> -->
    <div class="opacity-70 text-center" v-else>
      <div v-html="$t('text_active_monitors')"></div>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  total: Number,
  totalDown: Number,
});

const totalUp = computed(() => {
  return props.total - props.totalDown;
});
</script>
