<template>
  <div class="base-input" :class="{ error: errorMessage }">
    <div class="flex justify-between items-center mb-1">
      <label class="flex-1" v-if="label" :for="$attrs.id"
        >{{ label }}
        <span class="text-red-600 font-medium" v-if="required">*</span></label
      >
      <slot name="label-more"></slot>
    </div>
    <div class="relative">
      <input
        v-bind="$attrs"
        :type="inputType"
        :value="value"
        :class="$attrs.class"
        v-on="validationListeners"
      />
      <!-- password eyes 👁️ -->
      <span
        class="password-icon absolute top-1/2 -translate-y-1/2 right-2 cursor-pointer"
        @click="visible = !visible"
      >
        <span class="material-symbols-rounded">{{
          !visible ? "visibility" : "visibility_off"
        }}</span>
      </span>
      <!-- /password eyes 👁️ -->
    </div>
    <span v-if="errorMessage" class="error-message">{{ errorMessage }}</span>
  </div>
</template>

<script>
// use normal <script> to declare options
export default {
  inheritAttrs: true,
};
</script>

<script setup>
import { ref, computed } from "vue";
import { useField } from "vee-validate";

const props = defineProps({
  name: String,
  label: String,
  initialValue: String,
  required: Boolean,
});

const visible = ref(false);

const inputType = computed(() => (visible.value ? "text" : "password"));

const { value, errorMessage, handleChange, handleBlur } = useField(
  () => props.name,
  undefined,
  {
    validateOnValueUpdate: false,
    initialValue: props.initialValue,
  }
);

const validationListeners = {
  blur: (evt) => handleBlur(evt, true),
  change: handleChange,
  input: (evt) => handleChange(evt, !!errorMessage.value),
};
</script>

<style lang="postcss" scoped>
.base-input {
  @apply w-full relative;

  label {
    @apply text-sm font-semibold block;
  }

  input {
    @apply py-1 px-3 border-zinc-200 border rounded-md w-full dark:border-zinc-600 dark:bg-zinc-900;

    &::placeholder {
      @apply text-zinc-400;
    }

    &:focus,
    &:focus-visible {
      @apply border border-neptune-400 outline-none ring-inset ring-1 ring-neptune-400;
    }
  }

  &.error {
    label {
      @apply text-red-600;
    }

    input {
      @apply border-red-600;

      &:focus,
      &:focus-visible {
        @apply ring-red-600;
      }
    }

    .error-message {
      @apply text-xs mt-1 font-medium text-red-600;
    }
  }
}

.password-icon {
  .material-symbols-rounded {
    @apply text-xl text-zinc-600;
  }
}
</style>
