<template>
  <BaseBox class="flex flex-col">
    <h3 class="font-bold mb-3">{{ $t('text_query_last') }} 30 {{ $t('text_days') }}</h3>
    <Line v-if="Object.keys(chartData).length > 0" :data="chartData" :options="chartOptions" ref="chartQueries" />
  </BaseBox>
</template>

<script setup>
import { ref, onBeforeMount } from "vue";
import { Line } from "vue-chartjs";

import customParseFormat from "dayjs/plugin/customParseFormat";
import dayjs from "dayjs";
dayjs.extend(customParseFormat);

import BaseBox from "@/components/ui/BaseBox.vue";
import { storeToRefs } from "pinia";
import { useUserStore } from "@/stores/UserStore";
import { statistics } from "../../api/base";
import { i18n } from "@/i18n";
const { t } = i18n.global;
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
} from "chart.js";
import { onBeforeRouteUpdate } from "vue-router";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler
);

const userStore = useUserStore();
const { mode } = storeToRefs(userStore);
const chart = ref([]);
const chartLabels = ref([]);
const chartValues = ref([]);
const chartQueries = ref(null);
const chartData = ref({});
const chartOptions = ref({});

const createChartData = () => {
  return {
    labels: chartLabels,
    datasets: [
      {
        data: chartValues,
        fill: "start",
        pointBorderColor: "#70b6c0",
        pointHoverBorderColor: "#50949E",
        pointBorderWidth: 3,
        pointHoverBorderWidth: 3,
        pointBackgroundColor: mode.value == "light" ? "#ffffff" : "#27272a",
        borderColor: "#70b6c0",
        backgroundColor:
          mode.value == "light" ? "rgba(80, 148, 158, .25)" : "rgba(80, 148, 158, .5)",
        tension: 0.5,
        radius: 5,
        pointRadius: 5,
        pointHoverRadius: 5,
        pointColor: mode.value == "light" ? "#ffffff" : "#27272a",
      },
    ],
    options: {
      transitions: {
        update: {
          animation: {
            duration: 200,
          },
        },
      },
      animation: {
        duration: 0,
      },
      scales: {
        yAxes: [
          {
            gridLines: {
              drawBorder: false,
            },
          },
        ],
      },
      maintainAspectRatio: false,
    },
  };
};

const createChartOptions = () => {
  return {
    plugins: {
      legend: {
        display: false,
        labels: {
          font: {
            size: 20,
          },
        },
      },
      tooltip: {
        backgroundColor: mode.value == "light" ? "#ffffff" : "#3f3f46",
        bodyColor: mode.value == "light" ? "#27272a" : "#fff",
        titleColor: mode.value == "light" ? "#27272a" : "#fff",
        borderWidth: 1,
        borderColor: mode.value == "light" ? "#e4e4e7" : "#52525b",
        bodyFont: {
          family: "Nunito Sans",
        },
        titleFont: {
          family: "Nunito Sans",
        },
        padding: 8,
        usePointStyle: true,
        callbacks: {
          labelPointStyle: function (context) {
            return {
              pointStyle: "circle",
              rotation: 0,
              margin: 10
            };
          },
          labelColor: function (context) {
            return {
              backgroundColor: context.dataset.borderColor,
              borderColor: context.dataset.borderColor,
              borderWidth: 0,
            };
          },
          label: function (context) {
            return ' ' + context.raw
          },
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: "#939395",
          font: {
            size: 12,
            family: "Nunito Sans",
            weight: "bold",
          },
        },
      },
      y: {
        grid: {
          color: mode.value == "light" ? "#e4e4e7" : "#3f3f46",
        },
        ticks: {
          color: "#939395",
          font: {
            size: 12,
            family: "Nunito Sans",
            weight: "bold",
          },
        },
      },
    },
  };
};

const createLabels = () => {
  return chart.value.map((data) => {
    let formattedDate = dayjs(data.date, "DD/MM/YYYY").format("MMM DD");
    return formattedDate;
  })
}

const createValues = () => chart.value.map((data) => {
  return data.value;
})

const initChart = async () => {
  try {
    const res = (await statistics.readStatistics('general'))?.statistics || [];
    chart.value = res;
    chartLabels.value = createLabels();
    chartValues.value = createValues();
    chartOptions.value = createChartOptions();
    chartData.value = createChartData();
  }
  catch (e) {
    console.error(t('text_error_read_statistics'), e);
  }
}

onBeforeRouteUpdate(async () => {
  await initChart();
});

onBeforeMount(async () => {
  await initChart();
});

</script>
