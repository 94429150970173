<template>
  <form class="space-y-4" @submit="onSubmit">
    <BaseInput id="commitMessage" :label="$t('label_commit_message')" name="commitMessage" :placeholder="$t('label_commit_message')"
      type="text" />
    <div class="text-end mt-4">
      <BaseButton size="sm" formnovalidate :disabled="isSubmitting || !meta.valid">{{ $t('button_save') }}</BaseButton>
    </div>
    <AlertMessage v-if="error" status="error">{{ error }}</AlertMessage>
  </form>
</template>

<script setup>
import { ref } from "vue";
import * as yup from "yup";
import { useForm } from "vee-validate";
import { useClusterStore } from "@/stores/ClusterStore";
import useToastStore from "@/stores/toast";
const { addToast } = useToastStore();
import { i18n } from "@/i18n";
const { t } = i18n.global;
const props = defineProps({
  clusterID: {
    type: [Number, String],
    required: true,
  },
  fileContent: {
    type: String,
    required: true,
  },
  filename: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(["closeModal"]);

const error = ref("");
const clusterStore = useClusterStore();

const schema = yup.object({
  commitMessage: yup.string().required(t('validation_required_field'))
});

const { isSubmitting, handleSubmit, meta } = useForm({
  validationSchema: schema,
});

const onSubmit = handleSubmit.withControlled(async (values) => {
  try {
    const payload = {
      ...values,
      configurationFileData: props.fileContent,
    };

    await clusterStore.updateConfigurationFileData(
      payload,
      props.clusterID,
      props.filename
    );
    emit("closeModal");
    addToast({
      title: t("text_update_config_file_success"),
      variant: "success",
    });

  } catch (err) {
    addToast({
      title: t("text_configuration_file_update_failure"),
      variant: "danger",
    });
    // error.value = err;
  }
});
</script>
