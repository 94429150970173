<template>
  <BaseBox class="zone-missing grid grid-cols-12 gap-y-4 md:gap-x-4">
    <div class="col-span-12 md:col-span-6">
      <h3 class="text-lg font-bold mb-2 dark:text-red-400">
        {{ $t('text_unconfigured_zone_title')}}
      </h3>
      <p class="text-sm">{{ $t('text_unconfigured_zone_not_active') }}</p>
      <p class="text-sm">
        {{ $t('text_unconfigured_zone_enabling_message') }}
      </p>
      <p class="text-sm mb-3">
        {{ $t('text_unconfigured_find_list_msg') }}
      </p>
      <BaseButton size="sm" @click="$emit('onCheckAgain')">{{ $t('button_check_again') }}</BaseButton>
    </div>
    <div class="col-span-12 md:col-span-6">
      <h3 class="text-md font-semibold mb-1">{{ $t('text_current_nameservers_title') }}:</h3>
      <div v-if="!currentNS">
        <p class="text-sm my-3"> {{ $t('text_unconfigured_zone_invalid_domain') }} </p>
      </div>
      <div v-else-if="currentNS.length === 0">
        <p class="text-sm my-3"> {{ $t('text_unconfigured_zone_empty_nameservers') }} </p>
      </div>
      <ul v-else class="text-sm list-disc ml-8 mb-3">
        <li v-for="(ns, index) in currentNS" :key="'curr' + index">
          {{ ns }}
        </li>
      </ul>
      <h3 class="text-md font-semibold mb-1">{{ $t('text_unconfigured_zone_nameservers_to_set') }}:</h3>
      <ul class="text-sm list-disc ml-8">
        <li v-for="(ns, index) in expectedNS" :key="'curr' + index">
          {{ ns }}
        </li>
      </ul>
    </div>
  </BaseBox>
</template>
<script setup >
import { storeToRefs } from "pinia";
import { useZonesStore } from "../../stores/ZonesStore";

const zoneStore = useZonesStore();
const {
  currentNS,
  expectedNS
} = storeToRefs(zoneStore);

</script>
<style lang="postcss" scoped>
.zone-missing {
  @apply bg-red-50 border-red-300 dark:border-red-400 border-2;
  position: relative;

  & * {
    z-index: 1;
    position: relative;
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    opacity: .1;

    @apply bg-transparent dark:bg-red-500;
  }
}
</style>
