<template>
  <BaseBox>
    <div class="flex justify-between">
      <div class="space-y-4">
        <div>
          <div class="flex gap-x-2">
            <h3
              class="font-medium"
              :class="{
                'text-green-500': pop.status == 'HEALTHY',
                'text-red-500': pop.status == 'UNHEALTHY',
                'text-gray-500 line-through': !pop.active,
              }"
            >
              {{ pop.code }}
            </h3>
            <strong>{{ pop.local_ip }}</strong>
          </div>
          <p>{{ $t('text_up_from') }}: 
            {{ $te('text_' + timePassed(pop.uptime).replace(/ /g, "_").toLowerCase()) ? 
                $t('text_' + timePassed(pop.uptime).replace(/ /g, "_").toLowerCase()) : 
                timePassed(pop.uptime) 
              }}
            </p>
        </div>
        <div v-if="monitorData">
          <small>{{ $t('text_last_24_global_activity') }}</small>
          <StatusBars :data="monitorData.statusDetails.uptimeDetails.daily" />
        </div>
      </div>
      <div class="flex flex-col justify-between items-end">
        <div class="text-end space-y-1">
          <p>Iaac Serial: {{ pop.serial }}</p>
          <BaseButton
            v-if="checkPermission('edit_cluster_pop')"
            size="sm"
            :variant="pop.active ? 'warning' : 'success'"
            @click="$emit(pop.active ? 'popDisable' : 'popEnable', pop.code)"
            >{{ pop.active ? $t("button_disable") : $t("button_enable") }}</BaseButton
          >
        </div>
        <a
          v-if="pop.log_link && checkPermission('view_pop_logs')"
          :href="pop.log_link"
          class="hover:underline text-neptune-400"
          >{{ $t("text_open_pop_logs") }}</a
        >
      </div>
    </div>
  </BaseBox>
</template>

<script setup>
import { monitor } from "@/api/base";
import { ref } from "vue";
import { timePassed } from "@/utilities/helpers";
import { useAuthStore } from "@/stores/AuthStore";

const authStore = useAuthStore();
const { checkPermission } = authStore;

const props = defineProps({
  pop: {
    type: Object,
    default: () => ({}),
  },
});

const monitorData = ref(null);

monitor.read(props.pop.monitorID).then(({ monitor }) => {
  monitorData.value = monitor;
});
</script>
