import { defineStore } from "pinia";
import router from "@/routes";
import { auth } from "@/api/base";
import { useUserStore } from "./UserStore";

export const useAuthStore = defineStore("AuthStore", {
  // state
  state: () => ({
    auth_token: "",
    lang: navigator.languages && navigator.languages.length
            ? navigator.languages[0].split('-')[0].toLocaleLowerCase()
            : navigator.language.split('-')[0].toLocaleLowerCase() || 'en'
  }),
  // getters
  getters: {
    isLoggedIn() {
      return this.auth_token.length > 0;
    }
  },
  // actions
  actions: {
    checkPermission(permission) {
      const {user} = useUserStore();
      return ( user.permissions.includes('*') || user.permissions.includes(permission));
    },
    async login(payload) {
      const data = await auth.login(payload);
      this.auth_token = data.auth_token;
      const { addUser } = useUserStore();
      addUser(data.user);
    },
    async requestReset(payload) {
      return await auth.requestReset(payload);
    },
    async resetCredentials(payload) {
      return await auth.resetCredentials(payload);
    },
    async logout() {
      await auth.logout();
      this.resetLogout();
    },
    async resetLogout() {
      this.$reset();
      router.push({ name: "HomePage" });
    },
    async validateResetToken(payload) {
      await auth.requestVerifyToken(payload);
    },
    setLang(lang) {
      this.lang = lang
    }
  },
  persist: {
    storage: localStorage,
  },
});
