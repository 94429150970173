<template>
  <div class="grid grid-cols-12 gap-y-10 md:gap-x-10">
    <div class="col-span-12 md:col-span-6">
      <PageTitle />
      <p>ID: {{ poolsStore.pool.id }}</p>
      <p>CODE: {{ poolsStore.pool.poolCode }}</p>
    </div>
    <div v-if="Object.keys(poolsStore.pool).length" class="col-span-12 md:col-span-6">
      <BaseBox class="space-y-4">
        <div class="flex md:flex-wrap items-end justify-between">
          <IconText :title="$t('title_virtual_machine')" :text="poolsStore.pool.vmCount" icon="computer" icon-size="text-5xl">{{
        poolsStore.pool.vmCount }}
          </IconText>
          <IconText :title="$t('title_memory')" icon="memory_alt" icon-size="text-5xl">{{
        convertToHigherUnit(poolsStore.pool.memory_mib, "MB")
      }}</IconText>
          <IconText title="CPU" icon="memory" icon-size="text-5xl">{{
          poolsStore.pool.vcpu
        }}</IconText>
          <IconText title="GHZ" icon="memory" icon-size="text-5xl">{{ poolsStore.pool.ghz }}GHZ</IconText>
          <IconText :title="$t('title_storage')" icon="database" icon-size="text-5xl">{{
        convertToHigherUnit(poolsStore.pool.storage_mib,
          "MB") }}
          </IconText>
        </div>
      </BaseBox>
    </div>
  </div>
  <VirtualMachine v-if="poolsStore.virtualMachineList" :data="poolsStore.virtualMachineList"
    :pool-id="route.params.id" />
</template>

<script setup>
import { useRoute } from "vue-router";
import { usePoolsStore } from "@/stores/PoolsStore";
import { convertToHigherUnit } from "@/utilities/helpers";

import VirtualMachine from "@/components/pool/VirtualMachine.vue";

const poolsStore = usePoolsStore();
const route = useRoute();

poolsStore.fetchPool(route.params.id);
</script>
