<template>
  <div class="radio">
    <label v-for="option in options" :key="option.value" :for="$attrs.value">
      <input
        v-model="value"
        type="radio"
        v-bind="$attrs"
        :value="option.value"
      />
      {{ option.label }}
    </label>
  </div>
</template>

<script>
// use normal <script> to declare options
export default {
  inheritAttrs: false,
};
</script>

<script setup>
import { useField } from "vee-validate";

const props = defineProps({
  name: String,
  options: Array,
  initialValue: String,
  modelValue: String,
});

const { value } = useField(() => props.name, undefined, {
  validateOnValueUpdate: false,
  syncVModel: true,
  initialValue: props.initialValue || props.modelValue,
});
</script>

<style lang="postcss" scoped>
.radio {
  @apply w-full flex gap-x-4 flex-wrap;

  label {
    @apply flex items-center gap-x-2 dark:text-zinc-50;
  }

  input {
    -webkit-appearance: none;
    appearance: none;
    @apply border-zinc-200 border rounded-full w-4 h-4 bg-white relative dark:border-zinc-600 dark:bg-zinc-900;

    &::after {
      content: "";
      opacity: 0;
      @apply bg-neptune-400 rounded-full absolute w-2.5 h-2.5;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &:checked {
      @apply border-neptune-500;

      &::after {
        opacity: 1;
      }
    }
  }
}
</style>
