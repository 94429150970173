<template>
  <BaseButton :style="{ position: 'absolute', right: ' 1.25rem', top: '-1.25rem' }" size="sm" @click="router.go(-1)"> 
    {{ $t('button_go_back') }}
  </BaseButton>

  <BaseBox class="grid grid-cols-12 gap-y-4 md:gap-x-4">
    <div class="col-span-12 md:col-span-6 space-y-2">
      <div>
        <h3 class="text-lg font-semibold">{{ $t('text_manage_pool_title') }}</h3>
        <p v-html="$t('text_manage_pool_desc')"/>
      </div>
    </div>
    <div class="col-span-12 md:col-span-6 space-y-2">
      <h3 class="text-lg font-semibold">{{ $t('text_type_of_pool') }}</h3>
      <p>
        <strong>Balanced</strong> - {{ $t('text_pool_balanced_desc') }}
      </p>
      <p>
        <strong>Failover</strong> - {{ $t('text_pool_failover_desc') }}
      </p>
    </div>
  </BaseBox>
  <h2 class="text-2xl font-semibold">{{ $t('title_edit_pool') }}</h2>
  <BaseBox>
    <div v-if="getPoolZoneById(route.params.poolID)">
    </div>
    <ZonePoolForm v-if="getPoolZoneById(route.params.poolID)" :initial-values="getPoolZoneById(route.params.poolID)"
      :is-editing="true" />
  </BaseBox>
</template>

<script setup>
import { useRoute } from "vue-router";
import ZonePoolForm from "@/components/zones/ZonePoolForm.vue";
import router from "@/routes";

import { storeToRefs } from "pinia";
import { useZonesStore } from "@/stores/ZonesStore";

const route = useRoute();

const zoneStore = useZonesStore();
const { getPoolZoneById } = storeToRefs(zoneStore);
</script>
