<template>
  <div class="base-input" :class="{ error: errorMessage }">
    <div v-if="label" class="flex justify-between items-center mb-1">
      <label class="flex-1" :for="$attrs.id">{{ label }}
        <span v-if="required" class="text-red-600 dark:text-red-400 font-medium">*</span></label>
      <slot name="label-more"></slot>
    </div>
    <div class="relative" :class="{'flex' : prepend}">
    <div class="prepend h-100" v-if="prepend">{{ prepend }}</div>
      <input v-bind="$attrs" :type="type" :value="value" :class="[$attrs.class, prepend ? '!rounded-l-none' : null]" v-on="validationListeners"
        @input="$emit('update:value', value)" />
    </div>
    <span v-if="errorMessage" class="error-message">{{ errorMessage }}</span>
  </div>
</template>

<script>
// use normal <script> to declare options
export default {
  inheritAttrs: true,
};
</script>

<script setup>
import { useField } from "vee-validate";

defineEmits(["update:value"]);
const props = defineProps({
  type: String,
  name: String,
  label: String,
  initialValue: [String, Number],
  required: Boolean,
  inputValue: {
    type: String,
    required: false,
    default: "",
  },
  prepend: String
});

const { value, errorMessage, handleChange, handleBlur } = useField(
  () => props.name,
  undefined,
  {
    validateOnValueUpdate: false,
    initialValue: props.initialValue,
  }
);



if (props?.inputValue) {
  value.value = props.inputValue;
}


const validationListeners = {
  blur: (evt) => handleBlur(evt, true),
  change: handleChange,
  keyup: handleChange,
  input: (evt) => handleChange(evt, !!errorMessage.value),
};
</script>

<style lang="postcss" scoped>
.base-input {
  @apply w-full relative;

  label {
    @apply text-sm font-semibold block opacity-75 dark:text-zinc-50;
  }

  input {
    @apply py-1 px-3 border-zinc-200 dark:border-zinc-600 border rounded-md w-full dark:bg-zinc-900 text-zinc-900 dark:text-zinc-50;

    &::placeholder {
      @apply text-zinc-400;
    }

    &:focus,
    &:focus-visible {
      @apply border border-neptune-400 outline-none ring-inset ring-1 ring-neptune-400;
    }
  }

  &.error {
    label {
      @apply text-red-600 dark:text-red-400;
    }

    input, .prepend {
      @apply border-red-600 dark:border-red-400;

      &:focus,
      &:focus-visible {
        @apply ring-red-600 dark:ring-red-400;
      }
    }

    .prepend {
      @apply text-red-600 dark:text-red-400;
    }

    .error-message {
      @apply text-xs mt-1 font-medium text-red-600 dark:text-red-400;
    }
  }

  .prepend {
    @apply py-1 px-2 border-zinc-200 dark:border-zinc-600 border-l rounded-l-md border-y dark:bg-zinc-900 text-zinc-900 dark:text-zinc-50 font-bold;
  }
}

.password-icon {
  .material-symbols-rounded {
    @apply text-xl text-zinc-600;
  }
}
</style>
