<template>
    <div class="flex flex-col gap-4 justify-center align-center">
        <p class="text-center">{{ $t('text_unauthorized') }}</p>
        <BaseButton class="mt-0 flex-start" size="sm" @click="router.push({ name: isLoggedIn ? 'DashboardPage' : 'LoginPage' })"> 
        {{ (isLoggedIn) ? $t('text_go_to_dashboard')  : $t('text_go_to_login') }} 
        </BaseButton>
    </div>
</template>

<script setup>
import router from "@/routes";
import { useAuthStore } from "../stores/AuthStore";
const authStore = useAuthStore();
const { isLoggedIn } = authStore;
</script>

<style lang="scss" scoped>

</style>