import client from "./client";
import crud from "../crud";

// Services
import Auth from "./services/Auth";
import User from "./services/User";
import Zone from "./services/Zone";
import Monitor from "./services/Monitor";
import Pool from "./services/Pool";
import VirtualMachine from "./services/VirtualMachine";
import Cluster from "./services/Cluster";
import Organization from "./services/Organization";
import Statistics from "./services/Statistics";
import Translation from "./services/Translation";

// Crud
Object.assign(User.prototype, crud);
Object.assign(Zone.prototype, crud);
Object.assign(Monitor.prototype, crud);
Object.assign(Pool.prototype, crud);
Object.assign(VirtualMachine.prototype, crud);
Object.assign(Cluster.prototype, crud);
Object.assign(Organization.prototype, crud);
Object.assign(Statistics.prototype, crud);
Object.assign(Translation.prototype, crud);

export const auth = new Auth(client, "auth");
export const user = new User(client, "user");
export const zone = new Zone(client, "zone");
export const monitor = new Zone(client, "monitor");
export const pool = new Zone(client, "resource-pool");
export const virtualMachine = new VirtualMachine(client, "vm");
export const cluster = new Cluster(client, "cluster");
export const organization = new Organization(client, "organization");
export const statistics = new Statistics(client, "statistics");
export const translation = new Translation(client, "translation");
