<template>
  <div class="h-full flex items-center">
    <div class="w-full p-6 flex flex-col items-center justify-center space-y-4">
      <h1 class="text-2xl text-center font-semibold">
        {{ $t('welcome_message') }}
      </h1>
      <RouterLink :to="{ name: 'LoginPage' }">
        <BaseButton>{{ $t('button_login') }}</BaseButton>
      </RouterLink>
    </div>
  </div>
</template>
