<template>
  <BaseBox class="space-y-4">
    <h2 class="text-2xl font-bold">{{ $t('title_your_pop') }}</h2>
    <PopItem
      v-for="pop in clusterStore.cluster.popList"
      :key="pop.code"
      :pop="pop"
      @pop-disable="handleDisablePop(clusterStore.cluster.id, pop.code)"
      @pop-enable="handleEnablePop(clusterStore.cluster.id, pop.code)"
    />
  </BaseBox>
</template>

<script setup>
import PopItem from "@/components/clusters/PopItem.vue";
import DisablePop from "@/components/clusters/DisablePop.vue";

import { useClusterStore } from "@/stores/ClusterStore";
import { useModalStore } from "@/stores/ModalStore";

const clusterStore = useClusterStore();
const modalStore = useModalStore();

function handleDisablePop(clusterID, code) {
  modalStore.openModal({
    title: "Disable Pop",
    component: DisablePop,
    props: {
      clusterID,
      code,
    },
  });
}

async function handleEnablePop(clusterID, code) {
  await clusterStore.enablePop(clusterID, code, {
    status: true,
  });
}
</script>
