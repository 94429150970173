<template>
  <div
    v-click-outside="() => (isSubMenuVisible = false)"
    class="dropdown relative z-20"
    :class="{ open: isSubMenuVisible }"
  >
    <span class="selector" @click="isSubMenuVisible = !isSubMenuVisible">
      <slot name="menuitem" />
      <span v-if="showChevron" class="material-symbols-rounded">
        {{ isSubMenuVisible ? "expand_less" : "expand_more" }}
      </span>
    </span>
    <div v-show="isSubMenuVisible" class="sub-menu" :class="alignment">
      <slot name="submenu" />
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
const isSubMenuVisible = ref(false);

defineProps({
  showChevron: {
    type: Boolean,
    default: true,
  },
  alignment: {
    type: String,
    default: "right",
    validator(value) {
      return ["left", "center", "right"].includes(value);
    },
  },
});
</script>

<style lang="postcss" scoped>
.dropdown {
  .selector {
    @apply cursor-pointer flex items-center gap-1;
    @apply flex items-center gap-2;
    cursor: pointer;
    position: relative;

    .material-symbols-rounded {
      font-size: 20px;
    }

    &::after {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: calc(100% + 16px);
      height: calc(100% + 16px);
      z-index: -1;
      @apply rounded-md;
    }

    &:hover {
      &::after {
        background-color: theme("colors.neptune.400" / 7%);
      }
    }
  }

  .sub-menu {
    @apply absolute top-full mt-4 bg-white dark:bg-zinc-800 border-zinc-200 dark:border-zinc-600 border-[1px] shadow-lg rounded-md z-10 overflow-hidden;
    white-space: nowrap;

    &.left {
      @apply -left-2;
    }

    &.center {
      @apply left-1/2 transform -translate-x-1/2;
    }

    &.right {
      @apply -right-2;
    }

    .divider {
      height: 2px;
      background: black;
    }
  }

  &.open {
    .selector {
      &::after {
        background-color: theme("colors.neptune.400" / 12%);
      }
    }
  }
}
</style>
