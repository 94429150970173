<template>
  <div class="base-input" :class="{ error: errorMessage }">
    <label v-if="label" :for="$attrs.id"
      >{{ label }}
      <span class="text-red-600 font-medium dark:text-red-400" v-if="required"
        >*</span
      ></label
    >
    <input
      v-bind="$attrs"
      v-model="value"
      list="list"
      v-on="validationListeners"
    />
    <datalist id="list">
      <option
        v-for="option in options"
        :key="option.value"
        :value="option.value"
      >
        {{ option.label }}
      </option>
    </datalist>
  </div>
</template>

<script>
// use normal <script> to declare options
export default {
  inheritAttrs: false,
};
</script>

<script setup>
import { useField } from "vee-validate";

const props = defineProps({
  label: String,
  name: String,
  options: Array,
  initialValue: [String, Number],
  required: Boolean,
});

const { value, errorMessage, handleChange, handleBlur } = useField(
  () => props.name,
  undefined,
  {
    validateOnValueUpdate: false,
    initialValue: props.initialValue || props.options[0].value,
  }
);

const validationListeners = {
  blur: (evt) => handleBlur(evt, true),
  change: handleChange,
  input: (evt) => handleChange(evt, !!errorMessage.value),
};
</script>

<style lang="postcss" scoped>
.base-input {
  @apply w-full relative;

  label {
    @apply text-sm font-semibold block opacity-75 dark:text-zinc-50;
  }

  input {
    @apply py-1 px-3 border-zinc-200 dark:border-zinc-600 border rounded-md w-full dark:bg-zinc-900 text-zinc-900 dark:text-zinc-50;

    &::placeholder {
      @apply text-zinc-400;
    }

    &:focus,
    &:focus-visible {
      @apply border border-neptune-400 outline-none ring-inset ring-1 ring-neptune-400;
    }
  }

  &.error {
    label {
      @apply text-red-600 dark:text-red-400;
    }

    input {
      @apply border-red-600 dark:border-red-400;

      &:focus,
      &:focus-visible {
        @apply ring-red-600 dark:ring-red-400;
      }
    }

    .error-message {
      @apply text-xs mt-1 font-medium text-red-600 dark:text-red-400;
    }
  }
}
</style>
