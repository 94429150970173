import { defineStore } from "pinia";
import { organization } from "@/api/base";

export const useOrganizationsStore = defineStore("OrganizationsStore", {
  // state
  state: () => ({
    organizations: [],
  }),
  // getters
  getters: {},
  // actions
  actions: {
    async fetchOrganizations() {
      const { organizationList } = await organization.readAll();
      this.organizations = organizationList;
    },
  },
});
