<template>
  <header :class="mode">
    <!-- <Breadcrumbs class="text-xl" /> -->
    <RouterLink :to="{ name: 'DashboardPage' }" class="logo-wrapper" title="Deepstone">
      <AppLogo :mode="mode == 'light' ? 'dark' : 'light'" type="horizontal" />
    </RouterLink>
    <HeaderNav class="ml-auto" />
  </header>
</template>

<script setup>
import { computed } from "vue";
import HeaderNav from "@/components/header/HeaderNav.vue";
import AppLogo from "@/components/ui/AppLogo.vue";

import { useUserStore } from "@/stores/UserStore";

const userStore = useUserStore();

const mode = computed(() => {
  return userStore.mode;
});
</script>

<style lang="postcss" scoped>
header {
  @apply flex items-center justify-between p-5 w-screen z-10;

  transition: all .2s;
  background: rgba(theme("colors.white"), 0.65);
  backdrop-filter: blur(15px);
  width: 100%;

  :deep(.logo-wrapper) {
    align-self: stretch;
    position: relative;

    svg {
      height: 160%;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &.dark {
    background: rgba(theme("colors.zinc.700"), 0.65);
    @apply border-zinc-600;
  }
}
</style>
