<template>
  <div class="checkbox">
    <label v-if="label" class="text-sm" :for="$attrs.id">{{ label }}</label>
    <div class="relative">
      <input
        v-bind="$attrs"
        type="checkbox"
        :value="checkedValue"
        :checked="checked"
        :class="{ 'bg-red-100 border-red-500': errorMessage }"
        @change="handleChange"
        v-on="validationListeners"
      />
    </div>
    <span v-if="errorMessage" class="text-sm text-red-500">{{
      errorMessage
    }}</span>
  </div>
</template>

<script>
// use normal <script> to declare options
export default {
  inheritAttrs: false,
};
</script>

<script setup>
import { useField } from "vee-validate";
import { computed } from "vue";

const props = defineProps({
  name: String,
  label: String,
  checkedValue: [String, Number, Boolean],
  uncheckedValue: [String, Number, Boolean],
  initialValue: [String, Number, Boolean],
});

const checked = computed(() => props.initialValue === props.checkedValue);

const { errorMessage, handleChange, handleBlur } = useField(
  () => props.name,
  undefined,
  {
    type: "checkbox",
    checkedValue: props.checkedValue,
    uncheckedValue: props.uncheckedValue,
    initialValue: props.initialValue,
  }
);

const validationListeners = {
  blur: (evt) => handleBlur(evt, true),
  change: handleChange,
  input: (evt) => handleChange(evt, !!errorMessage.value),
};
</script>

<style lang="postcss" scoped>
.checkbox {
  @apply w-full;

  label {
    @apply flex items-center gap-x-2;
  }

  input {
    -webkit-appearance: none;
    appearance: none;
    @apply border-zinc-200 border w-4 h-4 bg-white relative dark:border-zinc-600 dark:bg-zinc-900 rounded;

    &::after {
      content: "";
      opacity: 0;
      @apply bg-neptune-400 absolute w-2.5 h-2.5 rounded-sm;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &:checked {
      @apply border-neptune-500;

      &::after {
        opacity: 1;
      }
    }
  }
}
</style>
