<template>
  <aside
    :class="twMerge('sidebar flex flex-shrink-0 flex-col bg-white dark:bg-zinc-800 sticky gap-5', $attrs.class, (store.sidebarCollapsed ? 'collapsed' : 'expanded'))"
    :style="`margin-top: ${headerHeight}px; height: calc(100vh - ${headerHeight}px)`"
  >
    <SidebarMenu class="flex-1" />

    <SidebarMenuItem :hasSubMenu="false" tag="a" @click="toggleSidebarCollapsed" :label="store.sidebarCollapsed ? $t('text_expand_sidebar') : $t('text_collapse_sidebar')" class="whitespace-nowrap">
      <span class="material-symbols-rounded">
        {{
          store.sidebarCollapsed ? "keyboard_double_arrow_right" : "keyboard_double_arrow_left"
        }}
      </span>
      <template v-if="!store.sidebarCollapsed">
      {{ store.sidebarCollapsed ? $t('text_expand_sidebar') : $t('text_collapse_sidebar') }}
      </template>
    </SidebarMenuItem>
  </aside>
</template>

<script setup>
import { ref } from "vue";

defineOptions({
  inheritAttrs: false,
});

const props = defineProps({
  headerHeight: Number,
});

import { twMerge } from "tailwind-merge";
import SidebarMenu from "@/components/sidebar/SidebarMenu.vue";
import SidebarMenuItem from "@/components/sidebar/SidebarMenuItem.vue";
import { useUserStore } from "@/stores/UserStore";

const store = useUserStore();

function toggleSidebarCollapsed() {
  store.toggleSidebarCollapsed()
}
</script>

<style lang="postcss" scoped>
.sidebar {
  transition: all .2s;

  &.collapsed {
    @apply w-20 px-4;
  }
}
</style>
