<template>
  <h2 class="text-xl font-semibold">{{ $t("title_recover_password") }}</h2>
  <form class="w-full space-y-8" @submit="onSubmit" @input="handleFormChange">
    <BaseInput
      id="email"
      label="Email"
      name="userEmail"
      placeholder="Email"
      type="email"
    />
    <BaseButton
      formnovalidate
      :disabled="isSubmitting || !meta.valid"
      class="block ml-auto"
      type="button"
      @click="onSubmit"
      >{{ $t("button_reset") }}</BaseButton
    >
    <AlertMessage v-if="error" status="error" class="text-center">{{
      error
    }}</AlertMessage>
    <AlertMessage v-if="success" status="success" class="text-center">
      {{ $t("text_check_email_address") }}
    </AlertMessage>
  </form>
</template>

<script setup>
import { ref } from "vue";

import * as yup from "yup";
import { useForm } from "vee-validate";

import { useAuthStore } from "@/stores/AuthStore";

const authStore = useAuthStore();

const error = ref(false);
const success = ref(false);

const schema = yup.object({
  userEmail: yup.string().required().email('Must be a valid email address!'),
});

const { isSubmitting, handleSubmit, meta, resetForm } = useForm({
  validationSchema: schema,
});

const onSubmit = handleSubmit(async (values) => {
  try {
    error.value = false;
    await authStore.requestReset({ ...values });
    success.value = true;
    resetForm();
  } catch (err) {
    error.value = err;
  }
});

function handleFormChange() {
  error.value = false;
  success.value = false;
}
</script>
