<template>
  <div class="flex justify-between items-center gap-4">
    <PageTitle class="flex-fill" />
    <div
      class="w-64"
      v-if="organizationOptions.length > 0 && checkPermission('read_organization_list')"
    >
      <BaseSelect
        :label="$t('text_organizations')"
        v-model="searchOrganization"
        name="searchOrganization"
        :options="[
          {
            value: '',
            label:  t('select_option_all'),
          },
          ...organizationOptions,
        ]"
      />
    </div>
  </div>
  <PoolList :pools="filteredPools" v-if="filteredPools.length" />
  <div class="text-center font-bold opacity-60" v-else>
    {{ $t("text_no_pools_available") }}
  </div>
</template>

<script setup>
import { usePoolsStore } from "@/stores/PoolsStore";
import PoolList from "@/components/pool/PoolList.vue";
import { i18n } from "@/i18n";
const { t } = i18n.global;
const poolsStore = usePoolsStore();

import { useOrganizationsStore } from "@/stores/OrganizationsStore";
import { computed, ref } from "vue";
import { storeToRefs } from "pinia";
import { useAuthStore } from "../stores/AuthStore";
const { checkPermission } = useAuthStore();

const { pools } = storeToRefs(poolsStore);

const organizationsStore = useOrganizationsStore();

// clusterStore.fetchClusters();
const searchOrganization = ref("");

const organizationOptions = computed(() =>
  organizationsStore.organizations.map((organization) => ({
    value: organization.id,
    label: organization.name,
  }))
);

const filteredPools = computed(() => {
  if (searchOrganization.value === "") {
    return pools.value;
  }
  return pools.value.filter((monitor) => {
    return monitor.organizationID === searchOrganization.value;
  });
});

// poolsStore.fetchPools();
</script>
