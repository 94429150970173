<template>
  <label class="relative inline-flex items-center cursor-pointer">
    <input v-model="model" type="checkbox" class="sr-only peer" :value="value" />
    <div
      class="w-9 h-5 bg-zinc-200 dark:bg-zinc-900 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-neptune-400 after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-zinc-200 dark:after:border-zinc-900 after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-neptune-400"
    ></div>
    <span class="ms-3 text-sm">{{ label }}</span>
  </label>
</template>

<script setup>
import { computed, defineEmits } from "vue";

const props = defineProps({
  modelValue: { type: [Array, Boolean] },
  value: { type: [Boolean, Object] },
  label: { type: String },
});

const emit = defineEmits(["update:modelValue"]);
const model = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});
</script>
