<template>
  <MonitorList
    :monitors="monitorsStore.getMonitorsUnhealthy"
  />
</template>

<script setup>
import { useMonitorsStore } from "@/stores/MonitorsStore";
import UptimeCounter from "@/components/monitors/UptimeCounter.vue";
import MonitorList from "@/components/monitors/MonitorList.vue";

const monitorsStore = useMonitorsStore();
</script>
