import BaseService from "../../BaseService";

export default class Zone extends BaseService {
  async readAllByOrganizationID(payload) {
    const { data } = await this._apiClient.get(`/${this._resource}`, {
      params: payload,
    });
    return data;
  }
  async readZoneConfigurationsNS(zoneID) {
    const { data } = await this._apiClient.get(`/${this._resource}/${zoneID}/validate`);
    return data
  }
  async createRecord(zoneID, payload) {
    const { data } = await this._apiClient.post(
      `/${this._resource}/${zoneID}/record`,
      payload
    );
    return data;
  }
  async createPool(zoneID, payload) {
    const { data } = await this._apiClient.post(
      `/${this._resource}/${zoneID}/pool`,
      payload
    );
    return data;
  }
  async updateRecord(zoneID, recordID, payload) {
    delete payload.type;
    const { data } = await this._apiClient.post(
      `/${this._resource}/${zoneID}/record/${recordID}`,
      payload
    );
    return data;
  }
  async toggleEnableZone(id, payload) {
    await this._apiClient.post(`/${this._resource}/${id}`, {
      disabled: payload,
    });
  }
  async updatePool(zoneID, poolID, payload) {
    delete payload.id;
    delete payload.zoneUpdatedAt;
    delete payload.createdAt;
    delete payload.updatedAt;
    delete payload.status;
    payload.endpoint.map((item) => delete item?.monitor);

    const { data } = await this._apiClient.post(
      `/${this._resource}/${zoneID}/pool/${poolID}`,
      payload
    );
    return data;
  }
  async deletePool(zoneID, poolID) {
    await this._apiClient.post(
      `/${this._resource}/${zoneID}/pool/${poolID}/delete`
    );
  }
  async deleteRecord(zoneID, recordID) {
    await this._apiClient.post(
      `/${this._resource}/${zoneID}/record/${recordID}/delete`
    );
  }
  async exportData(zoneID) {
    const { data } = await this._apiClient.get(
      `/${this._resource}/${zoneID}/export`
    );
    return data;
  }
  async importData(zoneID, payload) {
    const { data } = await this._apiClient.post(
      `/${this._resource}/${zoneID}/import`,
      payload
    );
    return data;
  }
  async exportZones() {
    const { data } = await this._apiClient.get(
      `/${this._resource}/export`
    );
    return data;
  }
  async importZones(payload) {
    const { data } = await this._apiClient.post(
      `/${this._resource}/import`,
      payload,
    );
    return data;
  }

}
