<template>
  <form class="space-y-4" @submit="onSubmit">
    <BaseInput id="domain" :label="$t('label_domain')" name="domain" :placeholder="$t('placeholder_domain_with_example')" type="text" required />
    <div class="text-end mt-4">
      <BaseButton mode="aggressive" size="sm">{{ $t("button_create") }}</BaseButton>
    </div>
    <AlertMessage v-if="error" status="error">{{ $te(error) ? $t(error) : error }}</AlertMessage>
  </form>
</template>

<script setup>
import * as yup from "yup";
import { ref } from "vue";
import { useForm } from "vee-validate";
import { useZonesStore } from "@/stores/ZonesStore";
import { validateDomain } from "@/utilities/helpers";
import useToastStore from "@/stores/toast";
const { addToast } = useToastStore();
import { i18n } from "@/i18n";
const { t } = i18n.global;

yup.addMethod(yup.string, "validateDomain", function (errorMessage) {
  return this.test(`test-domain`, errorMessage, function (value) {
    const { path, createError } = this;
    const validation = validateDomain(value);
    return (
      validation.status ||
      createError({ path, message: t(validation.reason) || errorMessage })
    );
  });
});

const schema = yup.object({
  domain: yup
    .string()
    .required(t('validation_required_field'))
    .validateDomain()
});

const { handleSubmit } = useForm({
  validationSchema: schema,
});

const emit = defineEmits(["closeModal"]);
const error = ref("");
const { createZone } = useZonesStore();

const onSubmit = handleSubmit.withControlled(async (values) => {
  try {
    await createZone(values);
    emit("closeModal");
    addToast({
      title: t('text_add_success_zone'),
      variant: "success",
    });
  } catch (err) {
    error.value = err;
    addToast({
      title: t('text_add_failure_zone'),
      content: err,
      variant: "warning",
    });
  }
});
</script>
