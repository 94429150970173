import { defineStore } from "pinia";
import { cluster } from "@/api/base";
import { i18n } from "@/i18n";
const { t } = i18n.global;
import { formatDate } from "@/utilities/helpers";

export const useClusterStore = defineStore("ClusterStore", {
  // state
  state: () => ({
    clusterList: [],
    cluster: {},
    firewallRules: [],
    backupList: [],
    configurationFileList: [],
    configurationFile: null,
    configurationFileData: null,
    configuration: null,
  }),
  // getters
  getters: {
    getFileHistory: (state) => state.configurationFile?.history,
    lastChange: (state) => {
      if (!state.configurationFile?.history) return "";

      const last =
        state.configurationFile?.history[0];
      return `${t('text_last_change_on')} ${formatDate(last?.last_update, "DD/MM/YYYY")} ${t('text_at')} ${formatDate(last?.last_update, "HH:MM")} ${last?.editor_name ? t('text_by') + ' ' + last?.editor_name : ''}`;
    },
    getFileContent: (state) => {
      if (state.configurationFileData) {
        const decodedData = atob(state.configurationFileData);
        return decodedData;
      }
      return null;
    },
  },
  // actions
  actions: {
    async fetchClusters() {
      const { clusterList } = await cluster.readAll();
      this.clusterList = clusterList;
    },
    async fetchCluster(id) {
      const { cluster: clusterData } = await cluster.read(id);
      this.cluster = clusterData;
      const { ruleSet } = await cluster.readFirewallRules(id);
      this.firewallRules = ruleSet;
      const { backupList } = await cluster.readBackups(id);
      this.backupList = backupList;
    },
    async enablePop(clusterID, popCode) {
      const { pop } = await cluster.enablePop(clusterID, popCode);
      this.cluster.popList = this.cluster.popList.map((popItem) =>
        popItem.code === pop.code ? pop : popItem
      );
    },
    async disablePop(clusterID, popCode) {
      const { pop } = await cluster.disablePop(clusterID, popCode);
      this.cluster.popList = this.cluster.popList.map((popItem) =>
        popItem.code === pop.code ? pop : popItem
      );
    },
    async deleteFirewallRule(clusterID, firewallID) {
      await cluster.deleteFirewallRule(clusterID, firewallID);
      this.firewallRules = this.firewallRules.filter(
        (rule) => rule.id !== firewallID
      );
    },
    async createFirewallRule(payload, clusterID) {
      const { rule } = await cluster.createFirewallRule(payload, clusterID);
      this.firewallRules.push(rule);
    },
    async downloadBackup(clusterID, backupID) {
      let {
        backup: { data, mime_type },
      } = await cluster.downloadBackup(clusterID, backupID);

      if (data.startsWith("77u/")) data = data.replace("77u/", "");

      const binaryData = atob(data);
      const blob = new Blob([binaryData], { mime_type });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `backup-${backupID}.txt`;
      a.click();
      URL.revokeObjectURL(url);
    },
    async fetchConfigurationFileList(clusterID) {
      const { configurationFileList } = await cluster.readConfigurationFileList(
        clusterID
      );
      this.configurationFileList = configurationFileList;
    },
    async fetchConfigurationFileData(clusterID, configurationFileName) {
      if (configurationFileName === "") {
        this.configurationFile = null;
        this.configurationFileData = null;
        return;
      }
      const { configurationFile, configurationFileData } =
        await cluster.readConfigurationFileData(
          clusterID,
          configurationFileName
        );
      this.configurationFile = configurationFile;
      this.configurationFileData = configurationFileData;
    },
    async updateConfigurationFileData(
      payload,
      clusterID,
      configurationFileName
    ) {
      const { configurationFile, configurationFileData } =
        await cluster.updateConfigurationFileData(
          {
            ...payload,
            configurationFileData: btoa(payload.configurationFileData),
          },
          clusterID,
          configurationFileName
        );
      this.configurationFile = configurationFile;
      this.configurationFileData = configurationFileData;
    },
    async fetchConfiguration(clusterID) {
      const { configuration } = await cluster.readConfiguration(clusterID);
      this.configuration = configuration;
    },
    async updateConfiguration(payload, clusterID) {
      const { configuration } = await cluster.updateConfiguration(
        payload,
        clusterID
      );
      this.configuration = configuration;
    },
    resetConfigurationFile() {
      this.configurationFile = null;
      this.configurationFileData = null;
    },
  },
});
