import { defineStore } from "pinia";
import { monitor } from "@/api/base";

export const useMonitorsStore = defineStore("MonitorsStore", {
  // state
  state: () => ({
    monitors: [],
    monitor: {},
  }),
  // getters
  getters: {
    getMonitorsHealthy: (state) => {
      return state.monitors.filter(
        (monitor) => monitor.status === "healthy" || !monitor.status
      );
    },
    getMonitorsUnhealthy: (state) => {
      return state.monitors.filter((monitor) => monitor.status === "unhealthy");
    },
    getMonitorsById: (state) => {
      return (id) => state.monitors.filter((monitor) => monitor.id == id);
    },
    getTotalMonitors: (state) => state.monitors.length,
    getTotalHealthyMonitors: (state) => state.getMonitorsHealthy.length,
    getTotalUnhealthyMonitors: (state) => state.getMonitorsUnhealthy.length,
  },
  // actions
  actions: {
    async fetchMonitors() {
      const { monitorList } = await monitor.readAll();
      this.monitors = monitorList;
    },
    async fetchMonitor(id) {
      const { monitor: monitorData } = await monitor.read(id);
      this.monitor = monitorData;
    },
    async updateMonitor(payload, id) {
      const { monitor: monitorData } = await monitor.update(payload, id);
      let monitorIndex = this.monitors.findIndex(monitor => monitor.id == id);
      this.monitors[monitorIndex] = monitorData;
      this.fetchMonitors()
    },
    async addMonitor(payload) {
      const data = await monitor.create(payload);
      this.monitors.push(data);
      return data;
    },
    async deleteMonitor(id) {
      const result = await monitor.delete(id);
      if (result) await this.fetchMonitors()
    }
  },
});
