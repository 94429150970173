<template>
  <BaseBox
    class="grid grid-cols-12 gap-y-4 md:gap-x-4 border-neptune-200 overflow-hidden"
  >
    <p class="deepstone-number">100</p>
    <div class="col-span-12 md:col-span-12 z-10">
      <div class="flex items-center justify-between mb-2 w-full">
        <h3 class="text-lg font-semibold">{{$t('text_manage_pools_title')}}</h3>
        <BaseButton
          v-if="authStore.checkPermission('create_zone_pool')"
          size="sm"
          class="block ml-auto"
          tag="RouterLink"
          :to="{
            name: 'ZonePoolCreatePage',
            params: { id: zone.id },
          }"
          >{{ $t('button_add_pool') }}</BaseButton
        >
      </div>
      <p class="text-sm">
        {{ $t('text_manage_pools_desc') }}
      </p>
    </div>
    <div class="col-span-12 z-10">
      <ZonePoolTable :data="poolList" />
    </div>
  </BaseBox>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useZonesStore } from "@/stores/ZonesStore";
import { useAuthStore} from "@/stores/AuthStore";

import ZonePoolTable from "@/components/zones/ZonePoolTable.vue";

const zoneStore = useZonesStore();
const { zone, poolList } = storeToRefs(zoneStore);
const authStore = useAuthStore();
</script>

<style lang="postcss" scoped>
.deepstone-number {
  font-size: 12rem;
  letter-spacing: -0.5rem;
  @apply font-extrabold leading-none text-neptune-100 dark:text-neptune-900 opacity-50 dark:opacity-20 pointer-events-none;
  position: absolute;
  top: -10%;
  left: -2%;
}
</style>
