<template>
  <div class="mt-1">
    <VAceEditor
      v-if="content"
      v-model:value="content"
      class="h-[500px]"
      :lang="'html' || fileLanguage"
      :print-margin="false"
      :readonly="true"
      :theme="mode == 'light' ? 'chrome' : 'ambiance'"
    />
  </div>
</template>

<script setup>
import { ref } from "vue";
import { cluster } from "@/api/base";
import { VAceEditor } from "vue3-ace-editor";
import "ace-builds/src-noconflict/mode-html";
import "ace-builds/src-noconflict/theme-chrome";
import "ace-builds/src-noconflict/theme-ambiance";
import { storeToRefs } from "pinia";
import { useUserStore } from "@/stores/UserStore";
const userStore = useUserStore();
const { mode } = storeToRefs(userStore);

const props = defineProps({
  clusterID: {
    type: [Number, String],
    required: true,
  },
  filename: {
    type: String,
    required: true,
  },
  fileLanguage: {
    type: String,
    required: true,
  },
  date: {
    type: [Number, String],
    required: true,
  },
});

const content = ref("");

async function fetchConfigurationFileData() {
  const { configurationFileData } = await cluster.readConfigurationFileHistory(
    props.clusterID,
    props.filename,
    props.date
  );
  content.value = atob(configurationFileData);
}

fetchConfigurationFileData();
</script>
