<template>
  <div v-if="virtualMachineStore.summary" class="flex justify-between items-center">
    <div class="space-y-2">
      <div class="flex gap-x-4">
        <h1 class="text-2xl font-bold text-zinc-800">
          {{ virtualMachineStore.summary.name }}
        </h1>
        <div class="flex gap-x-4">

          <BaseButton
            v-if="virtualMachineStore.summary.power_state == 'POWERED_ON' && checkPermission('power_off_virtual_machine')"
            variant="danger" size="sm" @click="
    changePowerStatus('off')
    ">
            {{ $t('button_power_off') }}
          </BaseButton>
          <BaseButton
            v-if="virtualMachineStore.summary.power_state == 'POWERED_ON' && checkPermission('reset_virtual_machine_power')"
            variant="warning" size="sm" @click="
    changePowerStatus('restart')
    ">
            {{ $t('button_power_reset') }}
          </BaseButton>
          <BaseButton
            v-if="virtualMachineStore.summary.power_state == 'POWERED_OFF' && checkPermission('power_on_virtual_machine')"
            variant="success" size="sm" @click="
    changePowerStatus('on')
    ">
            {{ $t('button_power_on') }}
          </BaseButton>
          <LoaderSpinner v-if="isLoading" />
        </div>
      </div>
      <BaseTag v-if="virtualMachineStore.summary.power_state == 'POWERED_ON'" variant="success" size="md"
        :text="$t('text_running')" />
      <BaseTag v-if="virtualMachineStore.summary.power_state == 'POWERED_OFF'" variant="danger" size="md"
        :text="$t('text_stopped')" />
    </div>
    <BaseButton size="sm self-start" variant="primary" type="text" tag="RouterLink"
      :to="{ name: 'PoolSinglePage', params: { id: route.params.poolID } }">
      {{ $t('button_back_to') }} Pool
    </BaseButton>
  </div>
  <div v-if="virtualMachineStore.summary && virtualMachineStore.details" class="flex justify-between">
    <BaseBox class="flex gap-x-4">
      <div>
        <img class="w-[500px]" v-bind:src="osImage" />
        <div class="flex">
          <p class="text-sm flex cursor-pointer" @click="openModal">{{ $t('text_click_to_open_console') }}</p>
        </div>

      </div>
      <ul class="px-8">
        <li>
          <strong>GUEST OS:</strong> {{ virtualMachineStore.summary.guest_OS }}
        </li>
        <li>
          <strong>UUID:</strong> {{ virtualMachineStore.summary.instance_uuid }}
        </li>
        <li>
          <strong>HOSTNAME:</strong>
          {{ virtualMachineStore.summary.hostname }}
        </li>
        <li>
          <strong>IPv4:</strong>
          {{ getIpAsStrings(virtualMachineStore.summary) }}
        </li>
        <li>
          <strong>IPv6:</strong>
          {{ getIpAsStrings(virtualMachineStore.summary, true) }}
        </li>
        <li>
          <strong>NETWORK:</strong>
          {{ getNetworkAsStrings(virtualMachineStore.summary) }}
        </li>
      </ul>
    </BaseBox>
    <BaseBox class="px-8 space-y-4">
      <IconText title="CPU" icon="memory" icon-size="text-5xl">{{
    virtualMachineStore.summary.cpu
  }}</IconText>
      <IconText :title="$t('title_memory')" icon="memory_alt" icon-size="text-5xl">{{
      convertToHigherUnit(virtualMachineStore.summary.memory_mib, "MB")
    }}</IconText>
      <IconText :title="$t('title_storage')" icon="database" icon-size="text-5xl">{{
      convertToHigherUnit(
        virtualMachineStore.summary.disk.diskCode.capacity_mib,
        "B"
      )
    }}
      </IconText>
    </BaseBox>
  </div>
  <div class="grid grid-cols-12 gap-4">
    <BaseBox class="col-span-4 max-h-64">
      <h3 class="text-lg font-semibold mb-2">{{$t('title_hardware_details')}}</h3>
      <!-- <ul v-if="virtualMachineStore.details" class="box overflow-y-scroll ml-0 max-h-full">
        <li class="flex flex-col gap-2 mr-2 last:mb-8" v-for="([key, value]) in hardwareDetails" :key="key">
          <strong>
            {{ key.replace(/([a-z])([A-Z])/g, '$1 $2').toUpperCase().split(".").join(" ").split("_").join(" ") }} :
          </strong>
          <pre>
            {{ value }}
          </pre>
        </li>
      </ul> -->
      {{ $t("text_not_enabled")}}.
    </BaseBox>
    <BaseBox class="col-span-8">
      <h3 class="text-lg font-semibold mb-2">{{$t('title_activity_diary')}}</h3>
      <p>{{ $t("text_not_enabled")}}.</p>
    </BaseBox>
    <BaseBox class="col-span-12">
      <h3 class="text-lg font-semibold mb-2">{{$t('title_statics')}}</h3>
      <p>{{ $t("text_not_enabled")}}.</p>
    </BaseBox>
  </div>
</template>

<script setup>
import { useRoute } from "vue-router";
import { useVirtualMachineStore } from "@/stores/VirtualMachineStore";
import { useModalStore } from "@/stores/ModalStore";
import { useAuthStore } from "@/stores/AuthStore";
import { convertToHigherUnit } from "@/utilities/helpers";
//import { flattenObject } from "@/utilities/helpers";
import { computed, ref } from "vue";
import VirtualMachineConsole from "../components/zones/VirtualMachineConsole.vue";
import useToastStore from "@/stores/toast";
import { getIpAsStrings } from "@/utilities/helpers";
import { i18n } from "@/i18n";
const { t } = i18n.global;
const { addToast } = useToastStore();

const route = useRoute();
const virtualMachineStore = useVirtualMachineStore();
const modalStore = useModalStore();
const authStore = useAuthStore();

virtualMachineStore.fetchVirtualMachine(route.params.vmCode);

const { checkPermission } = authStore;

const isLoading = ref(false);

const hardwareDetails = computed({
  get() {

    const selctedFields = ['boot', 'cpu', 'boot_devices', 'scsi_adapters', 'disks', 'hardware', 'sata_adapters', /* 'nics', */ 'memory', 'identity', 'instant_clone_frozen'];

    return Object.entries(virtualMachineStore.details).filter(([key,]) => selctedFields.includes(key));
  }
});

const osImage = computed(() => {
  let guest_OS = virtualMachineStore.summary.guest_OS;
  guest_OS = guest_OS.toLowerCase();
  let imagePath = ''
  switch (true) {
    case guest_OS.includes('windows'):
      imagePath += 'https://www.freepnglogos.com/uploads/windows-logo-png/windows-logo-logok-0.png';
      break;
    case guest_OS.includes('centos'):
      imagePath += 'https://cdn.freebiesupply.com/logos/large/2x/centos-1-logo-png-transparent.png';
      break;
    case guest_OS.includes('ubuntu'):
      imagePath += 'https://1000logos.net/wp-content/uploads/2017/06/Logo-Ubuntu.jpg';
      break;

    default:
      imagePath += 'https://1000logos.net/wp-content/uploads/2017/06/Logo-Ubuntu.jpg';
  }

  return imagePath;
})


function openModal() {
  modalStore.openModal({
    title: 'Console',
    component: VirtualMachineConsole
  })
}


function getNetworkAsArray(row) {
    const nicsCodes = Object.keys(row.nics);
    const result = new Set();

    for (let i = 0; i < nicsCodes.length; i++) {
        const nicCode = nicsCodes[i];
        const nic = row.nics[nicCode];
        const network = nic.backing.network;

        if (network) {
            result.add(network);
        }
    }

    return Array.from(result);
}


function getNetworkAsStrings(row) {
    return getNetworkAsArray(row).join(", ");;
}

async function changePowerStatus(statusToSet) {
  isLoading.value = true;
  try {
    let message = '';
    if (statusToSet === 'on') {
      await virtualMachineStore.powerOn(route.params.vmCode);
      message = t('text_message_vm_power_on');
    }
    if (statusToSet === 'off') {
      await virtualMachineStore.powerOff(route.params.vmCode);
      message = t('text_message_vm_power_off');
    }
    if (statusToSet === 'restart') {
      await virtualMachineStore.powerReset(route.params.vmCode);
      message = t('text_message_vm_power_reset');
    }
    addToast({
      title: message,
      variant: "success",
    });
  }
  catch (error) {
    addToast({
      title: error,
      variant: "danger",
    });
  }
  isLoading.value = false;
}

</script>

<style lang="postcss" scoped>
.box {
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}
</style>