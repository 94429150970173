<template>
  <div
    v-bind="$attrs"
    :class="
      twMerge(
        `toast inline-flex w-72 items-center justify-between gap-x-2 text-sm rounded-lg border border-black/10 dark:border-white/10 border-2 p-2 font-medium`,
        variants[variant],
        $attrs.class,
        mode
      )
    "
  >
    <div class="toast-content"><slot /></div>
    <button @click="emit('onClosing')">
      <span class="material-symbols-rounded">close</span>
    </button>
  </div>
</template>

<script setup>
const emit = defineEmits(["onClosing"]);
import { twMerge } from "tailwind-merge";
import { storeToRefs } from "pinia";

import { useUserStore } from "@/stores/UserStore";
const userStore = useUserStore();
const { mode } = storeToRefs(userStore);

defineProps({
  variant: {
    type: String,
    default: "primary",
    validator: (value) =>
      [
        "primary",
        "success",
        "danger",
        "warning",
        "gray",
        "lightGray",
        "green",
        "orange",
        "blue",
        "yellow",
        "magenta",
      ].includes(value),
  },
});

const variants = {
  info: "info",
  primary: "primary",
  success: "success",
  danger: "danger",
  warning: "warning",
};
</script>

<style lang="postcss" scoped>
.toast {
  backdrop-filter: blur(10px);
  transition: all 0.2s;

  &.info {
    @apply bg-zinc-200/50;

    :deep(.toast-content) {
      .toast-title,
      .toast-description {
        @apply text-zinc-600;
      }
    }

    &.dark {
      @apply bg-zinc-900/60;

      :deep(.toast-content) {
        .toast-title,
        .toast-description {
          @apply text-zinc-200;
        }
      }
    }
  }

  &.primary {
    @apply bg-neptune-300/50;

    :deep(.toast-content) {
      .toast-title,
      .toast-description {
        @apply text-neptune-600;
      }
    }

    &.dark {
      @apply bg-neptune-800/60;

      :deep(.toast-content) {
        .toast-title,
        .toast-description {
          @apply text-neptune-200;
        }
      }
    }
  }

  &.success {
    @apply bg-green-300/50;

    :deep(.toast-content) {
      .toast-title,
      .toast-description {
        @apply text-green-600;
      }
    }

    &.dark {
      @apply bg-green-800/60;

      :deep(.toast-content) {
        .toast-title,
        .toast-description {
          @apply text-green-200;
        }
      }
    }
  }

  &.danger {
    @apply bg-red-500/30;

    :deep(.toast-content) {
      .toast-title,
      .toast-description {
        @apply text-red-500;
      }
    }

    &.dark {
      @apply bg-red-900/60;

      :deep(.toast-content) {
        .toast-title,
        .toast-description {
          @apply text-red-200;
        }
      }
    }
  }

  &.warning {
    @apply bg-amber-300/50;

    :deep(.toast-content) {
      .toast-title,
      .toast-description {
        @apply text-amber-700;
      }
    }

    &.dark {
      @apply bg-amber-800/60;

      :deep(.toast-content) {
        .toast-title,
        .toast-description {
          @apply text-amber-200;
        }
      }
    }
  }

  button {
    transition: all 0.2s;
    mix-blend-mode: soft-light;

    @apply p-0.5 flex bg-black/10 dark:bg-white/10 rounded-full;

    .material-symbols-rounded {
      transition: all 0.2s;
      font-size: 1.25rem;
      font-weight: 600;
    }

    &:hover {
      @apply bg-black/20 dark:bg-white/20;
    }
  }
}
</style>
