<template>
    <p> {{ $t('text_already_logged') }} <RouterLink class="hover:underline hover:text-neptune-400"
            :to="{ name: 'DashboardPage' }">dashboard</RouterLink>
    </p>

</template>

<script setup>

</script>

<style lang="scss" scoped></style>