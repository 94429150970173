<template>
  <form :key="formKey" class="space-y-4" @submit="onSubmit">
    <div class="flex flex-col lg:flex-row gap-4">
      <BaseSelect id="type" :label="$t('label_type')" name="type" :options="typeOptions"
        @change="resetForm({ values: { type: values.type } })" required />
      <BaseInput id="name" :label="$t('label_name')" name="key" placeholder="@" type="text"
        :required="['SRV'].includes(values.type)" />
      <BaseInput v-if="!['POOL'].includes(values.type)" id="value" :label="$t('label_value')" name="value" :placeholder="$t('placeholder_value')"
        :style="{ 'min-width': (['TXT', 'SPF', 'CNAME'].includes(values.type)) ? '60%' : '' }" type="text" required />
      <BaseSelect v-if="values.type == 'POOL' && poolListOptions.length > 0" id="pool" label="Available pools"
        name="value" :options="poolListOptions" required />
      <BaseInput v-if="['MX', 'SRV'].includes(values.type)" id="priority" :label="$t('label_priority')" name="priority"
        :placeholder="$t('placeholder_priority')" type="number" min="0" max="65535" required />
      <BaseInput v-if="['SRV'].includes(values.type)" id="weight" :label="$t('label_weight')" name="weight" :placeholder="$t('placeholder_weight')"
        type="number" min="0" max="65535" required />
      <BaseInput v-if="['SRV'].includes(values.type)" id="port" :label="$t('label_port')" name="port" :placeholder="$t('placeholder_port')"
        type="number" min="0" max="65535" required />
      <div class="shrink-0 w-[160px]">
        <BaseSelect id="ttl" label="TTL" name="ttl" :options="ttlList" required />
      </div>
    </div>
    <div class="flex gap-x-4 items-end">
      <BaseTextarea id="comment" :label="$t('label_comment')" name="description" :placeholder="$t('placeholder_comment')" />
    </div>
    <div class="flex justify-end items-center gap-6">
      <BaseButton size="sm" type="text" variant="secondary" @click="$emit('closeZoneAddRecordForm')">{{ $t('button_cancel') }}</BaseButton>

      <BaseButton formnovalidate :disabled="isSubmitting || !meta.valid" size="sm">{{ $t('button_add') }} record</BaseButton>
    </div>
  </form>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import * as yup from "yup";
import { useForm } from "vee-validate";
import { useRoute } from "vue-router";

import { useZonesStore } from "@/stores/ZonesStore";


import { i18n } from "@/i18n";
const { t } = i18n.global;

import useToastStore from "@/stores/toast";
import { isV4, isV6 } from "@/utilities/helpers";
const { addToast } = useToastStore();
const { ttlList } = useZonesStore();
const formKey = ref(false);

defineEmits(["closeZoneAddRecordForm"]);

const initialValues = {
  type: 'A',
  ttl: 300
}

const schema = yup.object({
  type: yup.string().required(t('validation_required_field')),
  key: yup
    .string()
    .nullable(true)
    .notRequired()
    .max(60, t('validation_message_max_string') + '60')
    .when("type", {
      is: 'SRV',
      then: (schema) => schema
        .required(t('validation_required_field'))
        .matches(/^(?:[a-z0-9_.-]+|@)$/, t('validation_message_srv_name_required_record') )
    })
    .test(
      'is-valid-or-empty',
      t('validation_message_srv_name_record'),
      value => {
        return value === '' || /^(?:[a-z0-9_.-]*|@)$/.test(value);
      }
    ),
  ttl: yup.string()
    .when("type", {
      is: (val) => !["POOL"].includes(val),
      then: (schema) => schema.required(t('validation_required_field')),
    }),
  pool: yup.string(),
  description: yup
    .string()
    .max(256, t('validation_message_max_string') + '256'),
  priority: yup
    .number()
    .typeError(t('validation_number_type'))
    .min(0, t('validation_message_min_value') + '0')
    .max(65535, t('validation_message_max_value') + '65535')
    .when("type", {
      is: "SRV",
      then: (schema) => schema.required(t('validation_required_field')),
    }),
  weight: yup
    .number()
    .typeError(t('validation_number_type'))
    .min(0, t('validation_message_min_value') + '0')
    .max(65535, t('validation_message_max_value') + '65535')
    .when("type", {
      is: "SRV",
      then: (schema) => schema.required(t('validation_required_field')),
    }),
  port: yup
    .number()
    .typeError(t('validation_number_type'))
    .min(0, t('validation_message_min_value') + '0')
    .max(65535, t('validation_message_max_value') + '65535')
    .when("type", {
      is: "SRV",
      then: (schema) => schema.required(t('validation_required_field')),
    }),
  value: yup
    .string()
    .when("type", {
      is: (val) => !["POOL"].includes(val),
      then: (schema) => schema.required(t('validation_required_field')),
    })
    .when("type", {
      is: (val) => ["TXT", "SPF"].includes(val),
      then: (schema) => schema
        .required(t('validation_required_field'))
        .max(256, t('validation_message_max_string') + '256')
    })
    .when("type", {
      is: "A",
      then: (schema) =>
        schema.test(
          'is-valid-IPV4',
          t('validation_message_invalid_input') + 'IPv4',
          value => {
            return isV4(value);
          }
        ),
    })
    .when("type", {
      is: "AAAA",
      then: (schema) =>
        schema
          .test(
            'is-valid-IPV6',
            t('validation_message_invalid_input') + 'IPv6',
            value => {
              return isV6(value);
            }
          ),
    })
    .when("type", {
      is: "CNAME",
      then: (schema) =>
        schema
          .matches(/\.$/, t('validation_message_invalid_cname'))
          .max(250, t('validation_message_max_string') + '250'),
    }),
});

const { isSubmitting, handleSubmit, meta, resetForm, values } = useForm(
  {
    validationSchema: schema,
    initialValues
  }
);

const route = useRoute();
const { addRecord } = useZonesStore();

const zonesStore = useZonesStore();

const poolListOptions = computed(() => {
  return (
    zonesStore.poolList.map((pool) => {
      return { value: pool.id, label: pool.name };
    }) || []
  );
});

const types = [
  { value: "A", label: "A" },
  { value: "AAAA", label: "AAAA" },
  { value: "CNAME", label: "CNAME" },
  { value: "MX", label: "MX" },
  { value: "SRV", label: "SRV" },
  { value: "NS", label: "NS" },
  { value: "PTR", label: "PTR" },
  { value: "TXT", label: "TXT" },
  { value: "SPF", label: "SPF" },
];
const typeOptions = computed(() =>
  poolListOptions.value.length === 0
    ? types
    : [...types, { value: "POOL", label: "POOL" }]
);

onMounted(() => resetForm());

const onSubmit = handleSubmit(async (values) => {
  if (!values.key) values.key = '@';
  if (values.type == 'SRV') {
    const val = values.priority + ' ' + values.weight + ' ' + values.port + ' ' + values.value;
    values.value = val
    delete values.priority;
    delete values.weight;
    delete values.port;
  }
  await addRecord(route.params.id, { ...values })
    .then(() => {
      addToast({
        title: "Record " + t('text_create_generic_success'),
        variant: "success",
      });

      resetForm({ values: initialValues });
      //formKey.value = !formKey.value;
    })
    .catch((error) => {
      addToast({
        title: t('text_create_generic_failure') + 'record',
        content: error,
        variant: "danger",
      });
    });
});
</script>
