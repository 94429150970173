<template>
    <div class="flex items-center justify-start gap-2 flex-wrap">
        <template v-for="counter, idx in counters" :key="idx">
            <BaseTag :text="$t(counter.text)" :icon="counter.icon" :variant="counter?.variant"/>
        </template>
    </div>
</template>

<script setup>
import { computed } from 'vue';
import { i18n } from '@/i18n';
const {t} = i18n.global;
const props = defineProps({
    zone: Object,
    withMonitors: { type: Boolean, default: true },
    withPools: { type: Boolean, default: true },
});

const {zone } = props;


function count(type = 'monitor') {
    const res = [];
    const total = zone?.summary?.[type]?.count || 0;
    const unhealthyKey = type === 'monitor' ? 'unhealthy' : 'inactive';
    const healthyKey = type === 'monitor' ? 'healthy' : 'active';
    const unhealthy = zone?.summary?.[type]?.[unhealthyKey] || 0;
    const healthy = zone?.summary?.[type]?.[healthyKey] || 0;
    const warning = zone?.summary?.[type]?.warning || 0;
    const standBy = zone?.summary?.[type]?.standBy || 0;
    const status = zone?.summary?.[type]?.status || false;

    if( !total) {
        res.push({
            text :`text_no_${type}`,
            icon : "close"
        });
        return res
    }
    else if(total === unhealthy) {
        res.push(
        {
            text: `text_all_${type}_${type === 'monitor' ? 'unhealthy' : 'down'}`,
            variant: "danger",
            icon: "close"
        }
        );
        return res;
    }
    else if(total === warning) {
        res.push(
        {
            text: `text_all_${type}_warning`,
            variant: "warning",
            icon: "warning"}
        );
        return res;
    }
    else if(total === standBy) {
        res.push(
        {
            text: `text_all_${type}_not_active`,
            variant: "secondary",
            icon: "equal"
        }
        );
        return res;
    }
    else if(status && total > 0) {
        res.push(
        {
            text: `text_all_${type}_${type === 'monitor' ? 'healthy' : 'up'}`,
            variant: "success",
            icon: "check"
        }
        );
        return res;
    }
    else {

        if(healthy){
            res.push(
            {
                text:  `${healthy} ${healthy > 1 ? t(`text_${type}s_up`) : t(`text_${type}_up`)}`,
                variant: "success",
                icon: "check"
            })
            ;

        }
        if(unhealthy){
            res.push(
            {
                text:  `${unhealthy} ${unhealthy > 1 ? t(`text_${type}s_down`) : t(`text_${type}_down`)}`,
                variant: "danger",
                icon: "close"
            })
            ;

        }
        if(standBy){
            res.push(
            {
                text:  `${standBy} ${standBy > 1 ? t(`text_${type}s_standby`) : t(`text_${type}_standby`)}`,
                variant: "secondary",
                icon: "equal"
            })
            ;

        }
        if(warning){
            res.push(
            {
                text:  `${warning} ${warning > 1 ? t(`text_${type}s_not_intact`) : t(`text_${type}_not_intact`)}`,
                variant: "warning",
                icon: "warning"
            }
            );
        }
        return res;
    }
}


const counters = computed( () => {
    return [ ...count(), ...count('pool') ];
});

</script>

<style lang="scss" scoped></style>