<template>
  <div class="base-select" :class="{ error: errorMessage }">
    <label v-if="label" :for="$attrs.id"
      >{{ label }}
      <span class="text-red-600 font-medium dark:text-red-400" v-if="required"
        >*</span
      ></label
    >
    <div class="select-wrapper">
      <select v-bind="$attrs" v-model="value" v-on="validationListeners">
        <option
          v-for="option in options"
          :value="option.value"
          :key="option.value"
          :disabled="option.disabled"
        >
          {{ option.label }}
        </option>
      </select>
      <span class="material-symbols-rounded" :class="mode">expand_more</span>
    </div>
  </div>
</template>

<script>
// use normal <script> to declare options
export default {
  inheritAttrs: false,
};
</script>

<script setup>
import { useField } from "vee-validate";
import { storeToRefs } from "pinia";
import { useUserStore } from "@/stores/UserStore";

const props = defineProps({
  label: String,
  name: String,
  options: Array,
  initialValue: [String, Number],
  required: Boolean,
});

const { value, errorMessage, handleChange, handleBlur } = useField(
  () => props.name,
  undefined,
  {
    validateOnValueUpdate: false,
    initialValue: props.initialValue || props.options[0].value,
  }
);

const validationListeners = {
  blur: (evt) => handleBlur(evt, true),
  change: handleChange,
  input: (evt) => handleChange(evt, !!errorMessage.value),
};

const userStore = useUserStore();
const { mode } = storeToRefs(userStore);
</script>

<style lang="postcss" scoped>
@property --gradientColor {
  syntax: '<color>';
  initial-value: #fff;
  inherits: false;
}

.base-select {
  @apply w-full relative;

  label {
    @apply text-sm font-semibold mb-1 block opacity-75 dark:text-zinc-50;
  }

  .select-wrapper {
    @apply relative;

    .material-symbols-rounded {
      @apply absolute right-[1px] px-1 pl-6 top-1/2 transform -translate-y-1/2 pointer-events-none;
      background: linear-gradient(to left, var(--gradientColor) 40%, transparent);
      transition: --gradientColor .2s, all .2s;

      &.dark {
        --gradientColor: theme('colors.zinc.900')
      }
    }
  }

  select {
    @apply py-1 px-3 border-zinc-200 border rounded-md w-full dark:border-zinc-600 dark:bg-zinc-900 text-zinc-900 dark:text-zinc-50;
    appearance: none;

    &:focus,
    &:focus-visible {
      @apply border border-neptune-400 outline-none ring-inset ring-1 ring-neptune-400;
    }
  }

  &.error {
    label {
      @apply text-red-600 dark:text-red-400;
    }

    select {
      @apply border-red-600 dark:border-red-400;

      &:focus,
      &:focus-visible {
        @apply ring-red-600 dark:ring-red-400;
      }
    }

    .error-message {
      @apply text-xs mt-1 font-medium text-red-600;
    }
  }
}
</style>
