import BaseService from "../../BaseService";

export default class VirtualMachine extends BaseService {
  async powerOn(code) {
    await this._apiClient.post(`/${this._resource}/${code}/power/on`, null ,{timeout: import.meta.env.VITE_VIRTUAL_MACHINE_API_TIMEOUT});
  }
  async powerOff(code) {
    await this._apiClient.post(`/${this._resource}/${code}/power/off`, null ,{timeout: import.meta.env.VITE_VIRTUAL_MACHINE_API_TIMEOUT});
  }
  async powerReset(code) {
    await this._apiClient.post(`/${this._resource}/${code}/power/reset`, null, {timeout: import.meta.env.VITE_VIRTUAL_MACHINE_API_TIMEOUT});
  }
}
