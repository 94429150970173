<template>
  <BaseBox class="flex flex-col gap-4">
    <div class="flex items-center justify-between">
      <h3 class="font-bold">{{ $t('text_monitor_in_error') }}</h3>

      <BaseButton size="xs" variant="primary" type="text" tag="RouterLink" :to="{ name: 'MonitorPage' }"
        v-if="monitors && monitors.length">
        {{ $t('button_view_all') }}
      </BaseButton>
    </div>
    <ul class="flex flex-col gap-4" v-if="monitors && monitors.length">
      <li v-for="monitor in filteredMonitors" :key="monitor.id" class="flex justify-between items-end pb-4">
        <div class="">
          <div class="flex items-center gap-2 mb-2">
            <span class="material-symbols-rounded filled text-red-500 dark:text-red-400 opacity-50">
              warning
            </span>
            <h3 class="text-lg font-bold text-red-500 dark:text-red-400">
              {{ monitor.name }}
            </h3>
          </div>
          <p v-if="monitor.ip" class="text-sm font-semibold opacity-75">IP: {{ monitor.ip }}</p>
          <p v-if="monitor.name" class="text-sm font-semibold opacity-75">Record {{ $t('text_of') }}: {{ monitor.name }}</p>
        </div>
        <div class="text-right" v-if="monitor.statusDetails.lastEvent">
          <div class="text-sm">{{ $t('text_in_error_since') }}</div>
          <div class="text-red-500 dark:text-red-400 font-bold text-lg">
            {{ getLastEventFrom(monitor.statusDetails.lastEvent) }}
          </div>
        </div>
      </li>
    </ul>
    <h3 v-else>{{ $t('text_no_monitors') }}</h3>
  </BaseBox>
</template>

<script setup>
import { computed } from "vue";
import dayjs from 'dayjs';

const props = defineProps({
  monitors: Array,
  maxLength: {
    type: Number,
    default: 5,
  },
});

const filteredMonitors = computed(() => {
  return props.monitors.slice(0, props.maxLength);
});

function getLastEventFrom(date) {
  let currentDate = dayjs();
  let lastEventFromDate = dayjs(date)

  let difference = currentDate.diff(lastEventFromDate, 'minutes', true);

  let hours = parseInt(difference / 60);
  let minutes = dayjs().minute(difference).$m;

  return hours ? `${hours}h ${minutes}m` : `${minutes}m`;
}
</script>

<style lang="postcss" scoped>
li {
  @apply border-b border-zinc-200 dark:border-zinc-600;

  &:last-child {
    @apply border-0 p-0;
  }
}
</style>
