<template>
  <div class="search-bar">
    <div class="input-wrapper">
      <BaseInput :key="componentKey" :placeholder="$t('placeholder_search')" class="search-input" name="cerca" :inputValue="value"
        @update:value="(val) => (value = val)" @keyup.enter="$emit('onSearch', value)" @keyup="$emit('onSearch', value)"
        :disabled="$attrs.disabled" />
      <span v-if="value" class="material-symbols-rounded" @click="clearSearch()">
        close
      </span>
    </div>
    <BaseButton :disabled="!value" size="sm" class="search-button" @click="$emit('onSearch', value)">
      {{ $t("button_search") }}
    </BaseButton>
  </div>
</template>

<script setup>
import { ref } from "vue";

const value = ref("");
const componentKey = ref(0);

const props = defineProps({
  inputValue: {
    type: String,
    required: false,
    default: "",
  }
})

if (props.inputValue) {
  value.value = props.inputValue;
}

const emit = defineEmits(["onSearch"]);

function clearSearch() {
  value.value = "";
  emit("onSearch", value.value);
  componentKey.value++;
}



</script>

<style lang="postcss" scoped>
.search-bar {
  @apply flex;

  .input-wrapper {
    @apply flex-1 relative;

    :deep(.search-input) {
      input {
        @apply rounded-r-none;
      }
    }

    .material-symbols-rounded {
      @apply absolute bg-red-100 text-red-700 rounded-full p-[0.125rem] text-lg leading-none cursor-pointer;
      right: 5px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  :deep(.search-button) {
    @apply rounded-l-none py-0 px-3;
  }
}
</style>
