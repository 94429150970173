import { createRouter, createWebHistory } from "vue-router";

// Middlewares
import middlewarePipeline from "./middlewares";
import auth, { permissions } from "./middlewares/auth";

// Pages
import HomePage from "../pages/HomePage.vue";
import DashboardPage from "../pages/DashboardPage.vue";
import ZonesPage from "../pages/ZonesPage.vue";
import ZoneSinglePage from "../pages/ZoneSinglePage.vue";
import ZoneSinglePageIndex from "../pages/ZoneSinglePageIndex.vue";
import ZonePoolCreatePage from "../pages/ZonePoolCreatePage.vue";
import ZonePoolEditPage from "../pages/ZonePoolEditPage.vue";
import MonitorPage from "../pages/MonitorPage.vue";
import PoolPage from "../pages/PoolPage.vue";
import PoolSinglePage from "../pages/PoolSinglePage.vue";
import VirtualMachineSinglePage from "../pages/VirtualMachineSinglePage.vue";
import ClusterPage from "../pages/ClusterPage.vue";
import ClusterSinglePage from "../pages/ClusterSinglePage.vue";
import ClusterSinglePageIndex from "../pages/ClusterSinglePageIndex.vue";
import ClusterConfigurationFile from "../pages/ClusterConfigurationFile.vue";
import ClusterConfiguration from "../pages/ClusterConfiguration.vue";
import ExpiredResetLandingPage from "../pages/ExpiredResetLandingPage.vue";
import LoryPage from "../pages/LoryPage.vue";

// Auth Pages
import LoginPage from "../pages/LoginPage.vue";
import RecoveryPage from "../pages/RecoveryPage.vue";
import ResetCredentialsPage from "../pages/ResetCredentialsPage.vue";

import { useAuthStore } from "@/stores/AuthStore";
import { usePoolsStore } from "@/stores/PoolsStore";
import { useUserStore } from "@/stores/UserStore";
import { useZonesStore } from "@/stores/ZonesStore";
import UnauthorizedLandingPage from "../pages/UnauthorizedLandingPage.vue";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      name: "HomePage",
      component: HomePage,
      meta: {
        layout: "auth",
        breadcrumb: [
          {
            text: "Home",
          },
        ],
      },
    },
    {
      path: "/dashboard",
      name: "DashboardPage",
      component: DashboardPage,
      meta: {
        permission: "view_dashboard_page",
        layout: "default",
        middleware: [auth],
        breadcrumb: [
          {
            text: "Dashboard",
          },
        ],
      },
    },
    {
      path: "/zones",
      name: "ZonesPage",
      component: ZonesPage,
      meta: {
        permission: "view_zones_page",
        layout: "default",
        middleware: [auth, permissions],
        breadcrumb: [
          {
            text: "Zones",
          },
        ],
      },
    },
    {
      path: "/zones/:id",
      name: "ZoneSinglePage",
      component: ZoneSinglePage,
      meta: {
        permission: "view_zone_single_page",
        layout: "default",
        middleware: [auth, permissions],
      },
      children: [
        {
          path: "",
          name: "ZoneSinglePageIndex",
          component: ZoneSinglePageIndex,
          meta: {
            breadcrumb(route) {
              const zoneId = route.params.id;

              return [
                {
                  text: "Zones",
                  to: { name: "ZonesPage" },
                },
                {
                  text: zoneId,
                },
              ];
            },
          },
          beforeEnter: async (to) => {
            const { zone, validateZone, fetchZone } = useZonesStore();
            const props = { zone: zone.value, hash: '' }
            if (!zone.value) {
              props.zone = await fetchZone(to.params.id);
            }
            if (to.hash) {
              props.hash = to.hash.replace('#', '');
            }
            await validateZone(to.params.id);
            to.meta.props = props;
          }
        },
        {
          path: "pool/create",
          name: "ZonePoolCreatePage",
          component: ZonePoolCreatePage,
          meta: {
            permission: "view_create_pool_page",
            middleware: [permissions],
            breadcrumb(route) {
              const zoneId = route.params.id;

              return [
                {
                  text: "Zones",
                  to: { name: "ZonesPage" },
                },
                {
                  text: zoneId,
                  to: { name: "ZoneSinglePageIndex", params: { id: zoneId } },
                },
                { text: "Create pool" },
              ];
            },
          },
        },
        {
          path: "pool/:poolID/edit",
          name: "ZonePoolEditPage",
          component: ZonePoolEditPage,
          meta: {
            permission: "view_edit_pool_page", 
            middleware: [permissions],
            breadcrumb(route) {
              const zoneId = route.params.id;
              const poolId = route.params.poolID;

              return [
                {
                  text: "Zones",
                  to: { name: "ZonesPage" },
                },
                {
                  text: zoneId,
                  to: { name: "ZoneSinglePageIndex", params: { id: zoneId } },
                },
                { text: `Edit pool ${poolId}` },
              ];
            },
          },
        },
      ],
    },
    {
      path: "/monitor/:id?",
      name: "MonitorPage",
      component: MonitorPage,
      meta: {
        permission: "view_monitor_page",
        layout: "default",
        middleware: [auth, permissions],
        breadcrumb: [
          {
            text: "Monitor",
          },
        ],
      },
    },
    {
      path: "/pool",
      name: "PoolPage",
      component: PoolPage,
      meta: {
        permission: "view_resource_pool_page",
        layout: "default",
        middleware: [auth, permissions],
        breadcrumb: [
          {
            text: "Resource Pools",
          },
        ],
      },
    },
    {
      path: "/pool/:id",
      name: "PoolSinglePage",
      component: PoolSinglePage,
      meta: {
        permission: "view_resource_pool_single_page",
        layout: "default",
        middleware: [auth, permissions],
        breadcrumb(route) {
          const resPoolID = route.params.id;
          const { pool, pools } = usePoolsStore();


          let name = '';

          if (pool?.name) {
            name = pool.name;
          }
          else if (pools && pools.length > 0) {
            const index = pools.findIndex((p) => p.id == resPoolID)
            if (index >= 0) {
              name = pools[index].name;
            }
          }
          else {
            name = resPoolID;
          }

          return [
            {
              text: "Resource Pools",
              to: { name: "PoolPage" },
            },
            {
              text: name,
            },
          ];
        },
      },
    },
    {
      path: "/pool/:poolID/vm/:vmCode",
      name: "VirtualMachineSinglePage",
      component: VirtualMachineSinglePage,
      meta: {
        permission: "view_vm_page",
        layout: "default",
        middleware: [auth, permissions],
      },
    },
    {
      path: "/cluster",
      name: "ClusterPage",
      component: ClusterPage,
      meta: {
        permission: "view_cluster_page",
        layout: "default",
        middleware: [auth, permissions],
        breadcrumb: [
          {
            text: "Deep Cluster",
          },
        ],
      },
    },
    {
      path: "/cluster/:id",
      name: "ClusterSinglePage",
      component: ClusterSinglePage,
      children: [
        {
          path: "",
          name: "ClusterSinglePageIndex",
          component: ClusterSinglePageIndex,
        },
        {
          path: "configuration-file",
          name: "ClusterConfigurationFile",
          component: ClusterConfigurationFile,
          meta: {
            permission: "view_cluster_configuration_file_page",
            middleware: [permissions],
          }
        },
        {
          path: "configuration",
          name: "ClusterConfiguration",
          component: ClusterConfiguration,
          meta: {
            permission: "view_cluster_configuration_page",
            middleware: [permissions],
          }
        },
      ],
      meta: {
        permission: "view_cluster_single_page",
        layout: "default",
        middleware: [auth, permissions],
        breadcrumb: [
          {
            text: "Deep Cluster",
          },
        ],
      },
    },
    {
      path: "/lory",
      name: "LoryPage",
      component: LoryPage,
      meta: {
        permission: "view_lory_page",
        layout: "default",
        middleware: [auth, permissions],
        breadcrumb: [
          {
            text: "Lory",
          },
        ],
      },
    },
    {
      path: "/login",
      name: "LoginPage",
      component: LoginPage,
      meta: {
        layout: "auth",
      },
    },
    {
      path: "/recovery",
      name: "RecoveryPage",
      component: RecoveryPage,
      meta: {
        layout: "auth",
      },
    },
    {
      path: "/reset-credentials",
      name: "ResetCredentialsPage",
      component: ResetCredentialsPage,
      meta: {
        layout: "auth",
      },
    },
    {
      path: "/expired-reset-token",
      name: "ExpiredResetLandingPage",
      component: ExpiredResetLandingPage,
      meta: {
        layout: "auth",
      },
    },
    {
      path: "/unauthorized",
      name: "UnauthorizedPage",
      component: UnauthorizedLandingPage,
      meta: {
        layout: "auth",
      },
    },
  ],
});

router.beforeEach((to, from, next) => {

  const userStore = useUserStore();
  const authStore = useAuthStore();
  const storageUser = localStorage.getItem("UserStore");

  if (storageUser) {
    let stUsr = JSON.parse(storageUser);
    if (userStore.user._id !== stUsr.user._id) {
      userStore.addUser(stUsr.user);
      localStorage.setItem("AuthStore", JSON.stringify({ auth_token: authStore.auth_token, lang: authStore.lang }));
    }
  }

  const allowedUnprotectedRoutes = [
    "HomePage",
    "LoginPage",
    "RecoveryPage",
    "ResetCredentialsPage",
    "UnauthorizedLandingPage",
  ]

  if (allowedUnprotectedRoutes.includes(to.name) && authStore.isLoggedIn) {
      const name = (to.name == "ResetCredentialsPage") ? "ExpiredResetLandingPage" : "DashboardPage";
      return next({ name });
  }

  // const userStore = useUserStore();
  // userStore.setLightMode();

  if (!to.meta.middleware) {
    return next();
  }

  const middleware = to.meta.middleware;

  const context = {
    to,
    from,
    next,
  };

  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1),
  });
});

export default router;
