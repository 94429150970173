<template>
  <div class="menu-item-wrapper">
    <component
      :is="tag"
      :to="{ name: to }"
      class="menu-item flex items-center gap-x-3 rounded-md p-3"
      :data-label="label"
      active-class="active"
      @click="subMenuOpen === false ? $emit('toggleSubMenu', id) : null"
      :class="{ collapsed: sidebarCollapsed, dark: mode == 'dark' }"
    >
      <slot />
    </component>
    <span
      class="material-symbols-rounded rounded-full"
      v-if="hasSubMenu && !sidebarCollapsed"
      @click="$emit('toggleSubMenu', id)"
    >
      {{ subMenuOpen ? "expand_less" : "expand_more" }}
    </span>
  </div>
</template>

<script setup>
import { useUserStore } from "@/stores/UserStore";
import { storeToRefs } from "pinia";
const userStore = useUserStore();
const { sidebarCollapsed, mode } = storeToRefs(userStore);

defineProps({
  tag: {
    type: String,
    default: "RouterLink",
  },
  to: {
    type: String,
    default: "DashboardPage",
  },
  hasSubMenu: {
    type: Boolean,
    default: false,
  },
  subMenuOpen: {
    type: Boolean,
    default: false,
  },
  id: {
    type: String,
    default: "id",
  },
  label: {
    type: String,
    default: "Label",
  },
});

defineEmits(["toggleSubMenu"]);
</script>

<style lang="postcss" scoped>
.menu-item-wrapper {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  .material-symbols-rounded {
    cursor: pointer;
    &:hover {
      background: rgba(theme("colors.neptune.400"), 0.1);
    }
  }
}
.menu-item {
  flex: 1;
  font-weight: 700;
  cursor: pointer;

  &:hover {
    background: rgba(theme("colors.neptune.400"), 0.05);
    color: theme("colors.neptune.500");

    &.dark {
      color: theme("colors.neptune.400");
    }
  }

  &.active {
    background: rgba(theme("colors.neptune.400"), 0.1);
    color: theme("colors.neptune.500");

    &.dark {
      color: theme("colors.neptune.400");
    }
  }

  &.collapsed {
    @apply relative;
    z-index: 0;

    &::after {
      @apply hidden text-base text-zinc-600 dark:text-zinc-50 bg-white dark:bg-zinc-800 px-3 py-3 rounded-r-lg shadow-md ml-4 border-l-0 border border-zinc-200 dark:border-zinc-600;

      content: attr(data-label);
      position: absolute;
      left: 100%;
      top: 50%;
      transform: translateY(-50%) translateX(-1px);
      text-wrap: nowrap;
      z-index: -2;
    }

    &::before {
      @apply hidden absolute w-3 bg-white dark:bg-zinc-800;

      content: "";
      height: 120%;
      top: 50%;
      transform: translateY(-50%) translateX(-1px);
      z-index: -1;
      left: calc(100% + 0.25rem);
    }

    &:hover {
      &::after, &::before {
        @apply block;
      }
    }
  }
}
</style>
