import BaseService from "../../BaseService";

export default class Statistics extends BaseService {
  async readStatistics(type) {
    const { data } = await this._apiClient.get(
      `/${this._resource}/${type ? '?source=' + type : ''}`
    );
    return data;
  }

}
