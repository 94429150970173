<template>
  <div class="space-y-4">
    <p>{{ $t('text_upload_file') }}</p>
    <form class="space-y-4" @submit="onSubmit">
      <BaseInputFile name="file" :accept="extension"  />
      <div class="flex gap-x-4 justify-center">
        <BaseButton formnovalidate :disabled="isSubmitting || !meta.valid" type="submit">{{ $t('button_import') }}</BaseButton>
      </div>
    </form>
    <AlertMessage v-if="error" status="error">{{ error }}</AlertMessage>
  </div>
</template>

<script setup>
import { ref } from "vue";
import * as yup from "yup";
import { toTypedSchema } from "@vee-validate/yup";
import { useForm } from "vee-validate";

import { i18n } from "@/i18n";
const { t } = i18n.global;

import BaseButton from "@/components/ui/BaseButton.vue";
import AlertMessage from "@/components/ui/AlertMessage.vue";
import BaseInputFile from "@/components/form-elements/BaseInputFile.vue";
import { useZonesStore } from "@/stores/ZonesStore";
import useToastStore from "@/stores/toast";
const { addToast } = useToastStore();
const emit = defineEmits(["closeModal"]);

const props = defineProps({
  id: { type: Number, default: null },
  extension: { type: String, default: '.csv' },
});

const error = ref("");

const zoneStore = useZonesStore();

const schema = toTypedSchema(
  yup.object({
    file: yup.mixed().required(),
  })
);

const { isSubmitting, handleSubmit, meta, resetForm } = useForm({
  validationSchema: schema,
});

const onSubmit = handleSubmit(async (values) => {
    const reader = new FileReader();

    reader.onload = async (event) => {
      const base64String = event.target.result;
      const base64data = base64String.split(",")[1];
      try {
        if(props.id) await zoneStore.importData(props.id, base64data);
        else await zoneStore.importZones({ file: base64data });
        await zoneStore.fetchZone
        addToast({
          title: t('text_file_import_success'),
          variant: "success",
        });
      } catch (error) {
        addToast({
          title: t('text_file_import_failure'),
          variant: "danger"
        })
      }
    };

    reader.readAsDataURL(values.file);
    resetForm();
    emit("closeModal");
});
</script>
