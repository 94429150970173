<template>
  <BaseBox class="space-y-4">
    <div class="flex items-center justify-between w-full gap-3">
      <h3 class="text-lg font-semibold py-1">{{ $t('title_virtual_machine') }}</h3>
      <SearchBar class="w-80 mr-auto" @on-search="(value) => (search = value.toLowerCase())" />
    </div>
    <Table :data="rows" :searching="isSearching">
      <template #head>
        <tr>
          <th>Status</th>
          <th>{{ $t('table_header_name') }}</th>
          <th>{{ $t('table_header_memory') }}</th>
          <th>CPU</th>
          <th>{{ $t('table_header_disk') }}</th>
          <th>OS</th>
          <th>IPv4</th>
          <th>IPv6</th>
        </tr>
      </template>
      <template #body>
        <tr v-for="row in rows" :key="row.id">
          <td>
            <BaseTag v-if="row.power_state == 'POWERED_ON'" variant="success" size="md" :text="$t('text_running')" />
            <BaseTag v-else variant="danger" size="md" :text="$t('text_stopped')" />
          </td>
          <td>
            <RouterLink :to="{
        name: 'VirtualMachineSinglePage',
        params: { poolID: props.poolId, vmCode: row.code },
      }" class="hover:underline hover:text-neptune-400">{{ row.name }}</RouterLink>
          </td>
          <td>{{ convertToHigherUnit(row.memory_mib, "MB") }}</td>
          <td>{{ row.cpu }} vCpu</td>
          <td>
            {{ convertToHigherUnit(row.disk.diskCode.capacity_mib, "B") }}
          </td>
          <td>{{ row.guest_OS }}</td>
          <td>{{ getIpAsStrings(row) }}</td>
          <td>{{ getIpAsStrings(row, true) }}</td>
        </tr>
      </template>
    </Table>
  </BaseBox>
</template>

<script setup>
import { ref, computed } from "vue";
import { convertToHigherUnit, getIpAsArray, getIpAsStrings } from "@/utilities/helpers";
import { RouterLink } from "vue-router";
const props = defineProps({
  data: {
    type: Array,
    default: () => [],
  },
  poolId: {
    type: [Number, String],
  },
});

const search = ref("");

const isSearching = computed(() => {
  return search.value ? true : false;
});




const rows = computed(() => {
  return props.data.filter(
    (row) =>
      row.name.toLowerCase().includes(search.value) ||
      row.guest_OS.toLowerCase().includes(search.value) ||
      getIpAsArray(row).includes(search.value)
  );
});
</script>
