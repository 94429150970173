<template>
    <div>
        {{ $t('text_not_enabled_console') }}
    </div>
</template>

<script setup lang="ts">

</script>

<style scoped></style>