<template>
  <ManageConfigurationFile
    v-if="clusterStore.configurationFileList.length"
    :file-list="clusterStore.configurationFileList"
    :last-change="clusterStore.lastChange"
    :file-language="clusterStore.configurationFile?.language"
    :file-content="clusterStore.getFileContent || ''"
    @on-selected-file="handleChoseFile"
  />
  <VersionConfigurationFile
    v-if="clusterStore.configurationFile"
    :versions="clusterStore.getFileHistory"
    @on-preview-click="handlePreviewClick"
  />
</template>

<script setup>
import { ref, onUnmounted } from "vue";
import { useRoute } from "vue-router";
import router from "@/routes";
import { useClusterStore } from "@/stores/ClusterStore";
import { useModalStore } from "@/stores/ModalStore";

import ManageConfigurationFile from "@/components/clusters/ManageConfigurationFile.vue";
import VersionConfigurationFile from "@/components/clusters/VersionConfigurationFile.vue";
import PreviewConfigurationFile from "@/components/clusters/PreviewConfigurationFile.vue";

import { formatDate } from "@/utilities/helpers";

const route = useRoute();
const currentFilename = ref("");
const clusterStore = useClusterStore();
const modalStore = useModalStore();

async function handleChoseFile(filename) {
  await clusterStore.fetchConfigurationFileData(route.params.id, filename);
  currentFilename.value = filename;
}

onUnmounted(() => {
  clusterStore.resetConfigurationFile();
});

function handlePreviewClick(date) {
  modalStore.openModal({
    component: PreviewConfigurationFile,
    title: `Preview ${currentFilename.value} - ${formatDate(date)}`,
    size: "lg",
    props: {
      clusterID: route.params.id,
      filename: currentFilename.value,
      fileLanguage: clusterStore.configurationFile?.language,
      date,
    },
  });
}

async function checkIfConfigurationFiles() {
  if (!clusterStore.configurationFileList.length) {
    await clusterStore.fetchConfigurationFileList(route.params.id);

    if (!clusterStore.configurationFileList.length) {
      router.push({ name: "ClusterSinglePageIndex", params: { id: route.params.id } });
    }
  }
}

checkIfConfigurationFiles();
</script>
