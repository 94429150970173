import { defineStore } from "pinia";

export const useModalStore = defineStore("ModalStore", {
  // state
  state: () => ({
    title: "",
    component: null,
    props: {},
    size: "",
    resolveFunc: null,
    rejectFunc: null,
  }),
  // getters
  getters: {
    isOpen: (state) => (state.component ? true : false),
  },
  // actions
  actions: {
    openModal({ title, component, size, props }) {
      this.title = title;
      this.component = component;
      this.size = size || "md";
      this.props = props;
      document.body.classList.add("overflow-hidden");
    },
    openConfirmModal(options) {
      this.openModal(options);
      return new Promise((resolve, reject) => {
        this.resolveFunc = resolve;
        this.rejectFunc = reject;
      });
    },
    closeModal() {
      this.title = "";
      this.component = null;
      this.props = {};
      if (this.resolveFunc) this.rejectFunc(false);
      document.body.classList.remove("overflow-hidden");
    },
  },
});
