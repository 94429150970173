<template>
  <div class="flex justify-between items-center gap-4">
    <PageTitle class="flex-fill" />
    <div class="w-64" v-if="organizationOptions.length > 0 && checkPermission('read_organization_list')">
      <BaseSelect
        :label="$t('text_organization')"
        v-model="searchOrganization"
        name="searchOrganization"
        :options="[
          {
            value: '',
            label:  t('select_option_all'),
          },
          ...organizationOptions,
        ]"
      />
    </div>
  </div>
  <ClusterList :cluster-list="filteredClusters" />
</template>

<script setup>
import { useClusterStore } from "@/stores/ClusterStore";
import ClusterList from "@/components/clusters/ClusterList.vue";
import { useOrganizationsStore } from "@/stores/OrganizationsStore";
import { computed, ref } from "vue";
import { storeToRefs } from "pinia";
import { useAuthStore } from "../stores/AuthStore";
const { checkPermission } = useAuthStore();
import { i18n } from "@/i18n";
const { t } = i18n.global;

const clusterStore = useClusterStore();
const organizationsStore = useOrganizationsStore();

const { clusterList } = storeToRefs(clusterStore);

// clusterStore.fetchClusters();
const searchOrganization = ref("");

const organizationOptions = computed(() =>
  organizationsStore.organizations.map((organization) => ({
    value: organization.id,
    label: organization.name,
  }))
);

const filteredClusters = computed(() => {
  if (searchOrganization.value === "") {
    return clusterList.value;
  }
  return clusterList.value.filter((monitor) => {
    return monitor.organizationID === searchOrganization.value;
  });
});
</script>
