<template>
  <div class="space-y-4">
    <p>{{ $t('text_delete_zone_confirm') }}</p>
    <p>{{ $t('text_delete_zone_confirm_desc') }}</p>
    <BaseInput name="delete" @update:value="(val) => (value = val)" :value="value" />
    <div class="flex gap-x-4 justify-center">
      <BaseButton @click="handleDelete()" :disabled="value !== 'delete'">{{ ($t('button_delete')).tpUpperCase() }}</BaseButton>
    </div>
    <AlertMessage v-if="error" status="error">{{ $te(error) ? $t(error) : error }}</AlertMessage>
  </div>
</template>

<script setup>
import { ref } from "vue";
import router from "@/routes";
import { useZonesStore } from "@/stores/ZonesStore";
import useToastStore from "@/stores/toast";
import { i18n } from "@/i18n";
import { capitalizeFirst } from "../../utilities/helpers";
const { t } = i18n.global;

const { addToast } = useToastStore();

const emit = defineEmits(["closeModal"]);

const props = defineProps({
  id: Number,
});

const error = ref("");
const value = ref("");

const zoneStore = useZonesStore();

async function handleDelete() {
  try {
    await zoneStore.deleteZone(props.id);
    emit("closeModal");
    router.push({ name: "ZonesPage" });
    addToast({
      title: capitalizeFirst(t('text_zone'))  + t('text_delete_generic_success'),
      variant: "success",
    });
  } catch (err) {
    error.value = err;
    addToast({
      title: t('text_delete_zone_error'),
      variant: "danger",
    });
  }
}
</script>
