<template>
  <form class="space-y-4" @submit="onSubmit">
    <BaseInput id="name" :label="$t('label_rule_name')" name="name" :placeholder="$t('placeholder_rule_name')" type="text" required />
    <BaseInput id="port" :label="$t('label_port')" name="port" :placeholder="$t('placeholder_port')" type="number" min="23" required />
    <BaseRadio id="type" name="type" label="Type" :options="[
    { label: 'TCP', value: 'TCP' },
    { label: 'UDP', value: 'UDP' },
  ]" required />
    <BaseInput id="cidr_acl" :label="$t('label_ip_comma_separated')" name="cidr_acl" placeholder="0.0.0.0" type="text" required />
    <div class="text-end mt-4">
      <BaseButton size="sm" :disabled="isSubmitting || !meta.valid">{{ $t('button_create') }}</BaseButton>
    </div>
    <AlertMessage v-if="error" status="error">{{ error }}</AlertMessage>
  </form>
</template>

<script setup>
import { ref } from "vue";
import * as yup from "yup";
import { useForm } from "vee-validate";
import { useClusterStore } from "@/stores/ClusterStore";
import { validateIPList } from "../../utilities/helpers";
import { i18n } from "@/i18n";
const { t } = i18n.global;
const emit = defineEmits(["closeModal"]);

const error = ref("");
const clusterStore = useClusterStore();

yup.addMethod(yup.string, "validateIPList", function (errorMessage) {
  return this.test(`test-ip`, errorMessage, function (value) {
    const { path, createError } = this;
    const { status, reason } = validateIPList(value);
    return (
      status ||
      createError({ path, message: reason || errorMessage })
    );
  });
});


const schema = yup.object({
  name: yup.string().required(t('validation_required_field')).min(3, t('validation_message_min_string') + '3'),
  port: yup.number().min(23, 'validation_message_gte_than' + '23').required(t('validation_required_field')),
  type: yup.string().required(t('validation_required_field')),
  cidr_acl: yup.string().required(t('validation_required_field')).validateIPList(),
});

const { isSubmitting, handleSubmit, meta } = useForm({
  validationSchema: schema,
});

const onSubmit = handleSubmit.withControlled(async (values) => {
  try {
    await clusterStore.createFirewallRule(values, clusterStore.cluster.id);
    emit("closeModal");
  } catch (err) {
    error.value = err;
  }
});
</script>
