import { createApp } from "vue";
import store from "./stores";
import router from "./routes";
import vClickOutside from "click-outside-vue3";
import App from "./App.vue";
import GlobalComponents from "./globalComponents";
import { i18n, loadTranslations } from "./i18n";

import "./validations/messages";

import "./assets/style/icons.css";
import "./style.css";

const app = createApp(App);



async function initApp() {
    try {

        app.use(store);
        
        // Load translations
        await loadTranslations();
        
        app.use(i18n);
        app.use(router);
        app.use(GlobalComponents);
        app.use(vClickOutside);

        app.mount("#app");

    } catch (error) {
        console.error('Failed initializing the app:', error);
    }
}

async function main() {
    try {   
        await initApp();
    }
    catch(e){ 
        console.log("Some error occurred on initialization", e)
    }
    
}

(async () => await main())();