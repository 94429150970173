<template>
  <div class="flex items-center gap-4">
    <PageTitle type="zone">
      <template #title-info>
        <BaseTag v-if="zone?.disabled == false" type="success" :text="$t('text_active_zone')" size="md" variant="success" />
        <BaseTag v-if="zone?.disabled == true" type="warning" :text="$t('text_suspended_zone')" size="md" variant="warning" />
      </template>
    </PageTitle>
    <BaseDropdownItem v-if="currentRoute.name === 'ZoneSinglePageIndex'" class="ml-auto">

      <template #menuitem> {{ $t('button_actions') }} </template>

      <template #submenu>
        <HeaderMenuItem to="#" v-if="checkPermission('suspend_zone')" @click="handleToggleEnableZone(zone.id, !zone.disabled)">{{ zone.disabled ? $t('button_enable_zone')
          : $t('button_suspend_zone') }}</HeaderMenuItem>
        <HeaderMenuItem v-if="checkPermission('export_zone_single')" to="#" @click="handleExportZone(zone.id)">{{ $t('button_export_csv') }}</HeaderMenuItem>
        <HeaderMenuItem v-if="checkPermission('import_zone_single')" to="#" @click="handleImportFile(zone.id)">{{ $t('button_import_csv') }}</HeaderMenuItem>
        <HeaderMenuItem v-if="checkPermission('delete_zone')" to="#"
          class="text-red-500 dark:text-red-400 dark:hover:bg-red-950 hover:text-red-600 dark:hover:text-red-300 hover:bg-red-50 border-zinc-200 dark:border-zinc-600 border-t"
          @click="handleDeleteZone(zone.id)">{{ $t('button_delete_zone') }}</HeaderMenuItem>
      </template>
    </BaseDropdownItem>
  </div>
  <RouterView />
</template>

<script setup>
import { onMounted, watch, computed, ref } from "vue";
import { RouterView, useRoute } from "vue-router";
import { storeToRefs } from "pinia";

import HeaderMenuItem from "@/components/header/HeaderMenuItem.vue";

import DeleteZone from "@/components/zones/DeleteZone.vue";
import ConfirmModal from "@/components/ui/ConfirmModal.vue";
import ImportFileZone from "@/components/zones/ImportFileZone.vue";

import { useZonesStore } from "@/stores/ZonesStore";
import { useModalStore } from "@/stores/ModalStore";
import { useAuthStore } from "../stores/AuthStore";

import useToastStore from "@/stores/toast";
import { i18n } from "@/i18n";
const { t } = i18n.global;

const { addToast } = useToastStore();

const route = useRoute();

const currentRoute = computed(() => {
  return route;
});

const zoneStore = useZonesStore();
const { zone } = storeToRefs(zoneStore);

const authStore = useAuthStore();
const { checkPermission } = authStore;

const modalStore = useModalStore();

function handleDeleteZone(id) {
  modalStore.openModal({
    title: "Delete zone",
    component: DeleteZone,
    props: {
      id,
    },
  });
}

function handleImportFile(id) {
  modalStore.openModal({
    title: "Import file",
    component: ImportFileZone,
    props: {
      id,
    },
  });
}

async function handleToggleEnableZone(id, disabled) {
  const error = ref("");
  try {
    await modalStore.openConfirmModal({
      title: zone.value.disabled ? t('text_zone_enable') : t('text_zone_suspend'),
      component: ConfirmModal,
      props: {
        error,
      },
    });
    await zoneStore.toggleEnableZone(id, disabled);

    modalStore.closeModal();
    addToast({
      title: zone.value.disabled ? t('text_zone_enabling_success') : t('text_zone_suspending_success') ,
      variant: "success",
    });
  }
  catch (err) {
    error.value = err;
    addToast({
      title: zone.value.disabled ? t('text_zone_enabling_failure') : t('text_zone_suspending_failure') ,
      variant: "success",
    });
  }
}

async function handleExportZone(id) {
  try {
    await zoneStore.exportData(id, zone.value.domain);
    addToast({
      title: t('text_zone_export_success'),
      variant: "success"
    })    
  } catch (error) {
    addToast({
      title: t('title_error'),
      variant: "danger"        
    })
  }
}

onMounted(async () => {
  await zoneStore.fetchZone(route.params.id);
});

watch(
  () => route.params.id,
  async (id) => {
    await zoneStore.fetchZone(id);
  }
);
</script>
